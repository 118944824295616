import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import moment from "moment";
import React, {MouseEvent, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {RouteWithId} from "../../../routes/types";
import {
    selectSelectedPagesForReport,
    setSelectedPagesForAnnualReport
} from "../../CreateReport/ReportingSlice";
import {ReportConfig} from "../../models/Reporting";
import {AlertBanner, DropdownItem, PageActionMenu} from "../../../components";
import {DropdownGroup} from "xps-react";
import {FveDiscountRateType} from "../../../ClientManagement/AssetReliance/AssetRelianceButtonState";
import {
    resetAssetReliancePageActionMenu,
    selectAssetReliancePageActionMenu,
    selectAssetRelianceResponse,
    setAssetReliancePageActionMenu,
    setAssetReliancePageActionMenuForDetailedReport, setAssetReliancePageActionMenuForSummaryReport,
    setAssetRelianceResponse
} from "../../../ClientManagement/AssetReliance/AssetRelianceSlice";
import {emptyInvestorGroup, InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetsSummary} from "../../../Assets/models/Assets";
import {selectClientAssets, setClientAssets} from "../../../Assets/clientAssetsSlice";
import {wealthManagementApiClient} from "../../../ClientManagement/WealthManagementApiClient";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import {assetsApiClient} from "../../../Assets/AssetsApiClient";
import {resetAccordionTableState, setAccordionTableState} from "../../../components/AccordionTable/AccordionTableSlice";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import AssetRelianceContent from "../../../ClientManagement/AssetReliance/AssetRelianceContent";
import AssetRelianceFooter from "../../../ClientManagement/AssetReliance/AssetRelianceFooter";


export const EditableAssetRelianceReport = () => {

    const assetResponseForReport = useAppSelector(selectAssetRelianceResponse);
    const assetRelianceButtonStateForReport = useAppSelector(selectAssetReliancePageActionMenu);
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const accountHasAssetsForReport = !!assetResponseForReport.allAssetsStack.netAssets || !!assetResponseForReport.goals.totalPresentValue;
    const hasAssetInsufficiencyForReport: boolean = assetResponseForReport.allAssetsStack.excessAssets < 0;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const clientAssetsForReport: AssetsSummary | undefined = useAppSelector(selectClientAssets);
    const history = useHistory();
    const selectedPagesForAnnualReportFromRedux = useAppSelector(selectSelectedPagesForReport);
    const selectedPagesForAnnualReport: ReportConfig = JSON.parse(JSON.stringify(selectedPagesForAnnualReportFromRedux));


    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            wealthManagementApiClient.getAssetReliance(id),
            clientManagementApiClient.getInvestorGroup(id),
            assetsApiClient.getAssetsSummary(id),
        ]).then(([
                     assetRelianceResponse,
                     investorGroupResponse,
                     assetsSummaryResponse,
                 ]) => {
            dispatch(setAssetRelianceResponse(assetRelianceResponse));
            dispatch(setClientAssets(assetsSummaryResponse));
            setInvestorGroup(investorGroupResponse);
            setIsLoading(false);
        }).catch((error) => {
            console.error('Could not fetch asset reliance data', error.message);
        });
        return () => {
            dispatch(resetAccordionTableState());
        };
    }, [id])

    useEffect(() => {
        if(hasAssetInsufficiencyForReport) {
            dispatch(resetAssetReliancePageActionMenu());
        }
    }, [assetResponseForReport])

    if (isLoading) {
        return <LoadingIndicator/>
    }

    const cancelEditAssetRelianceReport = () => {
        history.push(`/Profile/${id}/ClientProfile/CreateReport`);
    };

    const takeScreenShot = () => {
        if(history.location.pathname.includes("EditAssetRelianceDetailedWithFutureValue"))
        {
            const selectedPagesConfig: ReportConfig = {
                ...selectedPagesForAnnualReport,
                assetRelianceDetailedWithFutureValue: {
                    ...selectedPagesForAnnualReport["assetRelianceDetailedWithFutureValue"],
                    isEnabled: true,
                }
            };

            dispatch(setSelectedPagesForAnnualReport(selectedPagesConfig));
            dispatch(setAssetReliancePageActionMenuForDetailedReport({
                ...assetRelianceButtonStateForReport
            }));
        }
        else{
            const selectedPagesConfig: ReportConfig = {
                ...selectedPagesForAnnualReport,
                assetRelianceWithFutureValue: {
                    ...selectedPagesForAnnualReport["assetRelianceWithFutureValue"],
                    isEnabled: true,
                }
            };

            dispatch(setSelectedPagesForAnnualReport(selectedPagesConfig));
            dispatch(setAssetReliancePageActionMenuForSummaryReport({
                ...assetRelianceButtonStateForReport
            }));
        }
        history.push(`/Profile/${id}/ClientProfile/CreateReport`);
    }

    const handleFveDiscountRate = (selectedFveDiscountRate: FveDiscountRateType) => {
        return (e: MouseEvent<HTMLElement>) => {
            dispatch(setAssetReliancePageActionMenu({
                ...assetRelianceButtonStateForReport,
                selectedFveDiscountRate: selectedFveDiscountRate,
            }));
            e.stopPropagation();
        };
    }

    function handleShowExcessAssets() {
        return (e: React.MouseEvent<HTMLInputElement>) => {
            dispatch(setAssetReliancePageActionMenu({
                ...assetRelianceButtonStateForReport,
                showExpectedExcessAsset: !assetRelianceButtonStateForReport.showExpectedExcessAsset
            }));
            dispatch(setAccordionTableState({
                expanded: {[`${id}-review-asset-reliance-excess-assets`]: !assetRelianceButtonStateForReport.showExpectedExcessAsset}
            }));
            e.stopPropagation();
        };
    }

    function handleIncludeLifeInsuranceAtDeath() {
        return (e: React.MouseEvent<HTMLInputElement>) => {
            dispatch(setAssetReliancePageActionMenu({
                ...assetRelianceButtonStateForReport,
                includeLifeInsuranceAtDeath: !assetRelianceButtonStateForReport.includeLifeInsuranceAtDeath
            }));
            e.stopPropagation();
        };
    }

    const noLifeInsurance = !clientAssetsForReport?.lifeInsurances || clientAssetsForReport?.lifeInsurances.data.length <= 0;
    const {ageFrom, numberOfYears} = investorGroup.planningPeriod;


    const component = (
           <PageActionMenu
                className="asset-reliance-header-container three-dots-menu page-action-menu edit-family-tree-menu marginright-12"
                panelHeight="auto"
                buttonKind="secondary"
                rounded={false}
                panelWidth={280}
                aria-label="show-asset-reliance-options"
                key={"page-action-menu"}
            >
                <DropdownGroup groupName=" " key={"ls-dropdown-group"} show={true}>
                    <DropdownItem className="asset-reliance-page-action-menu-options"
                                  value="show-expected-excess-at-age" itemText="">
                        <div className="show-fve-options"
                             onClick={handleShowExcessAssets()}>
                            Show Estimated Excess at Age {ageFrom + numberOfYears}
                            <input
                                id="showExpectedExcessAtAge"
                                name="show-expected-excess-at-age"
                                type="checkbox"
                                aria-label="show-expected-excess-at-age"
                                key={"show-expected-excess-at-age"}
                                onClick={handleShowExcessAssets()}
                                checked={assetRelianceButtonStateForReport.showExpectedExcessAsset}
                            />
                        </div>
                    </DropdownItem>
                    <DropdownItem className="asset-reliance-page-action-menu-options"
                                  value="include-life-insurance-at-death" itemText="">
                        <div className="show-fve-options"
                             onClick={handleIncludeLifeInsuranceAtDeath()}>
                            Include Life Insurance at Death
                            <input
                                data-testid={"include-life-insurance-at-death"}
                                id="includeLifeInsuranceAtDeath"
                                name="include-life-insurance-at-death"
                                type="checkbox"
                                aria-label="include-life-insurance-at-death"
                                key={"include-life-insurance-at-death"}
                                onClick={handleIncludeLifeInsuranceAtDeath()}
                                checked={assetRelianceButtonStateForReport.includeLifeInsuranceAtDeath}
                                disabled={!assetRelianceButtonStateForReport.showExpectedExcessAsset || noLifeInsurance}
                            />
                        </div>
                    </DropdownItem>
                </DropdownGroup>
                <DropdownGroup groupName="Growth Rate" key={"ls-dropdown-group"}>
                    <DropdownItem key="tndr">
                        <div style={{width: "100%"}} className="asset-reliance-page-action-menu-options"
                             onClick={handleFveDiscountRate(FveDiscountRateType.TRIPLE_NET)}>
                            Triple Net Growth Rate
                            <input name="triple-net-discount"
                                   data-testid={"triple-net-discount-option"}
                                   type="radio" value='tndr'
                                   aria-label="triple-net-discount-option"
                                   checked={assetRelianceButtonStateForReport.selectedFveDiscountRate === FveDiscountRateType.TRIPLE_NET}
                                   onClick={handleFveDiscountRate(FveDiscountRateType.TRIPLE_NET)}
                                   disabled={!assetRelianceButtonStateForReport.showExpectedExcessAsset}
                            />
                        </div>
                    </DropdownItem>
                    <DropdownItem className="page-action-menu-options" key={"dr-dropdown-group"}>
                        <div style={{width: "100%"}} className="asset-reliance-page-action-menu-options"
                             onClick={handleFveDiscountRate(FveDiscountRateType.NOMINAL_NET)}>
                            Nominal Net Growth Rate
                            <input name="nominal-net-discount"
                                   data-testid={"nominal-net-discount-option"}
                                   type="radio" value='nndr'
                                   aria-label="nominal-net-discount-option"
                                   checked={assetRelianceButtonStateForReport.selectedFveDiscountRate === FveDiscountRateType.NOMINAL_NET}
                                   onClick={handleFveDiscountRate(FveDiscountRateType.NOMINAL_NET)}
                                   disabled={!assetRelianceButtonStateForReport.showExpectedExcessAsset}
                            />
                        </div>
                    </DropdownItem>
                </DropdownGroup>
            </PageActionMenu>
    );

    return (
        <div>
            <DataEntryHeader
                title={"Asset Reliance Report Preview"}
                primaryButtonText="Take Screenshot"
                secondaryButtonText="Cancel"
                onPrimaryButtonClick={takeScreenShot}
                onSecondaryButtonClick={cancelEditAssetRelianceReport}
                isExtraHTMLElementRequired={true}
                extraHTMLElementComponent={component}
                SubtitleComponent={() => <span
                    className='holdings-subtitle'>{`As of ${moment().format('MM/DD/YYYY hh:mm A')}`}</span>}
            />

            <AlertBanner type={"info"} showAlert={assetRelianceButtonStateForReport.includeLifeInsuranceAtDeath!}>This includes the value of the death benefit of your
                Life Insurance policy, which may be included in your taxable estate.</AlertBanner>

            {
                <div className="asset-reliance">
                    <>
                        <AssetRelianceContent
                            goalsSummaryDTO={assetResponseForReport.goals}
                            allAssetsStack={assetResponseForReport.allAssetsStack}
                            assetStacks={assetResponseForReport.assetStacks}
                            excludedAssetStacks={assetResponseForReport.excludedAssetStacks}
                            id={id}
                            accountHasAssets={accountHasAssetsForReport}
                            headerOptions={assetRelianceButtonStateForReport}
                            investorGroup={investorGroup}
                            isEditScreen={true}
                        />
                    </>
                    <AssetRelianceFooter/>
                </div>
            }

        </div>
    )

}