import {
    AnnualGiftingStrategy,
    GiftToGrantorTrustStrategy,
    initialStateOfResidencyStrategy,
    StateInputDto,
    StateOfResidencyStrategy,
    StrategyDetailsSectionInfo,
    StrategyType
} from "./WealthPOTypes";
import moment from "moment/moment";
import {calculateYearsBetween, getTodayDate} from "../utils/dateUtils";
import {DISPLAY_DATE_FORMAT, ISO8601_DATE_FORMAT} from "../constants/common";
import {
    InvestorGroupMemberType,
    InvestorGroupType,
    PlanningPeriodType
} from "../ClientManagement/models/InvestorGroupType";

export interface PlanningPeriodValues {
    birthdate: string | null,
    firstName: string,
    planningPeriod: PlanningPeriodType | undefined
}

export interface DateAndAgeValues {
    startDate: string,
    endDate: string
    startYear: number | string,
    endYear: number | string,
    startAge: number | string,
    endAge: number | string
}

export interface InvestorGroupPostProcessingResponse {
    planningPeriodComputedValues: PlanningPeriodValues,
    dateAndAgeComputedValues: DateAndAgeValues
}

export interface StrategyTimeFrameValidationResponse {
    errorPresent: boolean,
    newYearsUntilFlow: number,
    newYearsOfFlow: number
}

export interface CreateOrEditStrategyParameters {
    strategyId?: number,
    description: string,
    contributionAmount: number,
    yearsUntilFlow: number,
    yearsOfFlow: number,
    yearsOfGrantorStatus: number
}

export const getStateNameForStateOfResidencyId = (listOfStates: StateInputDto[], id: string) => {

    const stateInputDto = listOfStates.find(state => state.id === `US-${id}`);

    if (stateInputDto) {
        const estateTaxPercentage = stateInputDto.forecastedEstateTaxRate * 100;
        return `${stateInputDto.name} (${estateTaxPercentage}%)`
    }

    return "";
};

export const getStateNamesForStateOfResidencyDropdown = (listOfStates: StateInputDto[]) => {
    return listOfStates.map((stateInputDto) => {
        const estateTaxPercentage = stateInputDto.forecastedEstateTaxRate * 100
        return `${stateInputDto.name} (${estateTaxPercentage}%)`
    });
};

export const getStateAbbreviationFromSelectedStateValue = (listOfStates: StateInputDto[], selectedState: string | undefined) => {
    if (selectedState === undefined) {
        return "";
    }

    const stateInputDTO = listOfStates.find(stateInputDtoFromList => {
        const estateTaxPercentage = stateInputDtoFromList.forecastedEstateTaxRate * 100
        return `${stateInputDtoFromList.name} (${estateTaxPercentage}%)` === selectedState;
    });

    return stateInputDTO?.id.split("-")[1];
};

export const navigateToWPOSummaryPage = (history: any, id: string) => {
    history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/Summary`);
}

export const validateYearsUntilFlow = (value: number, yearsUntilFlow: number, yearsOfFlow: number, planningPeriodValues: PlanningPeriodValues) => {
    let errorPresent = (value + yearsOfFlow > planningPeriodValues.planningPeriod!.numberOfYears);
    let newYearsUntilFlow: number = yearsUntilFlow;

    if (!errorPresent) {
        newYearsUntilFlow = value
    }

    return {
        errorPresent,
        newYearsUntilFlow,
        newYearsOfFlow: yearsOfFlow
    } as StrategyTimeFrameValidationResponse
}

export const validateYearsOfFlow = (value: number, yearsUntilFlow: number, yearsOfFlow: number, planningPeriodValues: PlanningPeriodValues) => {
    let errorPresent = (yearsUntilFlow + value > planningPeriodValues.planningPeriod!.numberOfYears);
    let newYearsOfFlow: number = yearsOfFlow

    if (!errorPresent) {
        newYearsOfFlow = value
    }

    return {
        errorPresent,
        newYearsUntilFlow: yearsUntilFlow,
        newYearsOfFlow
    }
}

export const validateStartYear = (newStartYear: number, yearsUntilFlow: number, yearsOfFlow: number, planningPeriodValues: PlanningPeriodValues, dateAndAgeValues: DateAndAgeValues) => {
    let errorPresent = false;
    const latestYearToStartGoal = (planningPeriodValues.planningPeriod!.startYear + planningPeriodValues.planningPeriod!.numberOfYears) - yearsOfFlow;
    let newYearsUntilFlow: number = yearsUntilFlow;

    if (!dateAndAgeValues.startYear) {
        errorPresent = true;
    } else if (newStartYear < moment().year()) {
        errorPresent = true;
        newYearsUntilFlow = 0;
    } else if (newStartYear > latestYearToStartGoal) {
        errorPresent = true;
    }

    if (!errorPresent) {
        let difference: number = newStartYear - Number(dateAndAgeValues.startYear);
        newYearsUntilFlow = yearsUntilFlow + difference;
    }

    return {
        errorPresent,
        newYearsUntilFlow,
        newYearsOfFlow: yearsOfFlow
    } as StrategyTimeFrameValidationResponse
}

export const validateEndYear = (newEndYear: number, yearsUntilFlow: number, yearsOfFlow: number, planningPeriodValues: PlanningPeriodValues, dateAndAgeValues: DateAndAgeValues) => {
    let errorPresent = false;
    let newYearsOfFlow: number = yearsOfFlow;

    if (!dateAndAgeValues.endYear) {
        errorPresent = true;
    } else if (newEndYear > planningPeriodValues.planningPeriod!.startYear + planningPeriodValues.planningPeriod!.numberOfYears) {
        errorPresent = true;
        newYearsOfFlow = planningPeriodValues.planningPeriod!.numberOfYears - yearsUntilFlow;
    } else if (newEndYear <= Number(dateAndAgeValues.startYear)) {
        errorPresent = true;
        newYearsOfFlow = 1;
    }

    if (!errorPresent) {
        let difference: number = newEndYear - Number(dateAndAgeValues.endYear);
        newYearsOfFlow = yearsOfFlow + difference;
    }

    return {
        errorPresent,
        newYearsUntilFlow: yearsUntilFlow,
        newYearsOfFlow
    }
}

export const validateStartAge = (newStartAge: number, yearsUntilFlow: number, yearsOfFlow: number, planningPeriodValues: PlanningPeriodValues, dateAndAgeValues: DateAndAgeValues) => {
    let errorPresent = false;
    let newYearsUntilFlow: number = yearsUntilFlow;
    let clientAge = moment().diff(planningPeriodValues.birthdate!, "years")

    if (!dateAndAgeValues.startAge) {
        errorPresent = true;
    } else if (newStartAge < clientAge) {
        errorPresent = true;
        newYearsUntilFlow = 0;
    } else if (newStartAge >= Number(dateAndAgeValues.endAge)) {
        errorPresent = true;
    }

    if (!errorPresent) {
        let difference: number = newStartAge - Number(dateAndAgeValues.startAge)
        newYearsUntilFlow = yearsUntilFlow + difference;
    }

    return {
        errorPresent,
        newYearsUntilFlow,
        newYearsOfFlow: yearsOfFlow
    } as StrategyTimeFrameValidationResponse
}

export const validateEndAge = (newEndAge: number, yearsUntilFlow: number, yearsOfFlow: number, planningPeriodValues: PlanningPeriodValues, dateAndAgeValues: DateAndAgeValues) => {
    let errorPresent = false;
    let newYearsOfFlow: number = yearsOfFlow;
    let availableYearsOfFlow = planningPeriodValues.planningPeriod!.numberOfYears - yearsUntilFlow;
    let maxAge = Number(dateAndAgeValues.startAge) + availableYearsOfFlow;

    if (!dateAndAgeValues.endAge) {
        errorPresent = true;
    } else if (newEndAge < Number(dateAndAgeValues.startAge)) {
        errorPresent = true;
        newYearsOfFlow = 1
    } else if (newEndAge > maxAge) {
        errorPresent = true;
        newYearsOfFlow = availableYearsOfFlow;
    }

    if (!errorPresent) {
        let difference: number = newEndAge - Number(dateAndAgeValues.endAge)
        newYearsOfFlow = yearsOfFlow + difference;
    }

    return {
        errorPresent,
        newYearsUntilFlow: yearsUntilFlow,
        newYearsOfFlow
    }
}

export const processInvestorGroupResponse = (investorGroup: InvestorGroupType, yearsUntilFlow: number, yearsOfFlow: number): InvestorGroupPostProcessingResponse => {
    let dateAndAgeComputedValues = {
        startDate: "",
        endDate: "",
        startYear: "",
        endYear: "",
        startAge: "",
        endAge: ""
    } as DateAndAgeValues

    let planningPeriodComputedValues = {
        birthdate: "",
        firstName: "",
        planningPeriod: undefined
    } as PlanningPeriodValues

    if (investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PRIMARY) {
        planningPeriodComputedValues = {
            birthdate: investorGroup.primaryMember.birthdate,
            firstName: investorGroup.primaryMember.firstName,
            planningPeriod: investorGroup.planningPeriod
        }

        dateAndAgeComputedValues = getDateAndAgeValues(investorGroup.primaryMember.birthdate, yearsUntilFlow, yearsOfFlow)
    } else if (investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PARTNER && investorGroup.partnerMember) {
        planningPeriodComputedValues = {
            birthdate: investorGroup.partnerMember.birthdate,
            firstName: investorGroup.partnerMember.firstName,
            planningPeriod: investorGroup.planningPeriod
        }

        dateAndAgeComputedValues = getDateAndAgeValues(investorGroup.partnerMember.birthdate, yearsUntilFlow, yearsOfFlow)
    }

    return {
        planningPeriodComputedValues,
        dateAndAgeComputedValues
    } as InvestorGroupPostProcessingResponse
}

export const getDateAndAgeValues = (birthday: string | null, yearsUntilFlow: number, yearsOfFlow: number): DateAndAgeValues => {
    if (birthday) {
        const todayDate: string = getTodayDate();

        const yearsBetween = Math.floor(calculateYearsBetween(birthday, todayDate));

        const startDateBeforeFormatting = moment(birthday).add(yearsBetween + yearsUntilFlow, 'years')
        const endDateBeforeFormatting = moment(startDateBeforeFormatting).add(yearsOfFlow, 'years');

        const startDate = startDateBeforeFormatting.format(DISPLAY_DATE_FORMAT)
        const endDate = endDateBeforeFormatting.format(DISPLAY_DATE_FORMAT)

        return {
            startDate,
            endDate,
            startYear: startDateBeforeFormatting.year().toString(),
            endYear: endDateBeforeFormatting.year().toString(),
            startAge: moment(startDateBeforeFormatting, ISO8601_DATE_FORMAT).diff(birthday, 'years', false).toString(),
            endAge: moment(endDateBeforeFormatting, ISO8601_DATE_FORMAT).diff(birthday, 'years', false).toString()
        } as DateAndAgeValues;
    }

    return {
        startDate: "",
        endDate: "",
        startYear: "",
        endYear: "",
        startAge: "",
        endAge: ""
    } as DateAndAgeValues;
}

export const convertStrategyTypeToString = (strategyType: StrategyType) => {
    let type: string = "";

    switch (strategyType) {
        case StrategyType.ANNUAL_GIFTING:
            type = "Annual Gifting";
            break;
        case StrategyType.STATE_OF_RESIDENCY:
            type = "State of Residency";
            break;
        case StrategyType.GIFT_TO_GRANTOR_TRUST:
            type = "Gift to Grantor Trust";
            break;
        default:
            return "";
    }

    return type;
}

export const buildStrategyDetailsList = (stateOfResidencyStrategy: StateOfResidencyStrategy,
                                         annualGiftingStrategyList: AnnualGiftingStrategy[],
                                         giftToGrantorTrustStrategyList: GiftToGrantorTrustStrategy[]): {
    strategyDetailsList: StrategyDetailsSectionInfo[],
    listOfStrategyDetailsLists: StrategyDetailsSectionInfo[][]
} => {

    let strategyDetailsList: StrategyDetailsSectionInfo[] = []

    if (stateOfResidencyStrategy !== initialStateOfResidencyStrategy) {
        strategyDetailsList.push({
            strategyId: stateOfResidencyStrategy.id ?? "",
            strategyType: StrategyType.STATE_OF_RESIDENCY,
            amountToBeneficiaries: stateOfResidencyStrategy.estimatedImpact.amountToBeneficiaries ?? 0,
            impactToEstEstateTax: stateOfResidencyStrategy.estimatedImpact.impactToEstEstateTax ?? 0,
            description: stateOfResidencyStrategy.description
        });
    }

    annualGiftingStrategyList.forEach(annualGiftingStrategy => {
        strategyDetailsList.push({
            strategyId: annualGiftingStrategy.strategyId ?? "",
            strategyType: StrategyType.ANNUAL_GIFTING,
            description: annualGiftingStrategy.description,
            impactToEstEstateTax: annualGiftingStrategy.estimatedImpact.impactToEstEstateTax ?? 0,
            amountToBeneficiaries: annualGiftingStrategy.estimatedImpact.amountToBeneficiaries ?? 0,
        })
    });

    giftToGrantorTrustStrategyList.forEach(giftToGrantorTrustStrategy => {
        strategyDetailsList.push({
            strategyId: giftToGrantorTrustStrategy.strategyId ?? "",
            strategyType: StrategyType.GIFT_TO_GRANTOR_TRUST,
            description: giftToGrantorTrustStrategy.description,
            impactToEstEstateTax: giftToGrantorTrustStrategy.estimatedImpact.impactToEstEstateTax ?? 0,
            amountToBeneficiaries: giftToGrantorTrustStrategy.estimatedImpact.amountToBeneficiaries ?? 0,
        })
    });

    let listOfStrategyDetailsLists: StrategyDetailsSectionInfo[][] = []

    for (let i = 0; i < strategyDetailsList.length; i += 5) {
        let endingIndex = i + 5;

        if (endingIndex >= strategyDetailsList.length) {
            listOfStrategyDetailsLists.push(strategyDetailsList.slice(i))
        } else {
            listOfStrategyDetailsLists.push(strategyDetailsList.slice(i, endingIndex))
        }
    }

    return {
        strategyDetailsList,
        listOfStrategyDetailsLists
    };
}
