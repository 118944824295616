import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../routes/types";
import StrategyWithTimeFrame from "./components/StrategyWithTimeFrame";
import {
    AnnualGiftingStrategy,
    EstimatedImpact,
    initialAnnualGiftingStrategy,
    initialEstimatedImpact,
    StrategyTimeFrame
} from "./WealthPOTypes";
import {wealthPOApiClient} from "./WealthPOApiClient";
import {CreateOrEditStrategyParameters} from "./WealthPOUtils";
import {useAppSelector} from "../store/hooks";
import {selectAnnualGiftingStrategies} from "./WealthPlanOptimizerSlice";

interface AddEditAnnualGiftingProps {
    refreshStrategiesSummary: () => void;
}

const AddEditAnnualGifting: React.FC<AddEditAnnualGiftingProps> = ({
                                                                       refreshStrategiesSummary
                                                                   }: AddEditAnnualGiftingProps) => {
    const {id, strategyId} = useParams<RouteWithIdAndStrategyId>();
    const history = useHistory();

    const annualGiftingStrategies = useAppSelector(selectAnnualGiftingStrategies);
    const annualGiftingStrategy = strategyId !== undefined
        ? annualGiftingStrategies.find((strategy => strategy.strategyId === strategyId))
        : initialAnnualGiftingStrategy

    const getEstimatedImpact = (requestBody: StrategyTimeFrame, signal?: AbortSignal): Promise<EstimatedImpact> => {
        if (strategyId) {
            requestBody = {
                ...requestBody,
                strategyId: strategyId
            }
        }
        return wealthPOApiClient.getEstimatedImpactForAnnualGifting(id, requestBody, signal);
    }

    const handleSaveClickAnnualGifting = (requestParameters: CreateOrEditStrategyParameters) => {
        const {description, contributionAmount, yearsUntilFlow, yearsOfFlow} = requestParameters;

        let requestBody = {
            description,
            contributionAmount,
            yearsUntilFlow,
            yearsOfFlow
        } as AnnualGiftingStrategy

        if (strategyId) {
            requestBody = {
                ...requestBody,
                strategyId: strategyId,
            };

            wealthPOApiClient.editAnnualGiftingStrategy(id, requestBody)
                .then(() => {
                    refreshStrategiesSummary();
                    history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/Summary`)
                });
        } else {
            wealthPOApiClient.createAnnualGiftingStrategy(id, requestBody)
                .then(() => {
                    refreshStrategiesSummary();
                    history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/Summary`)
                });
        }
    }

    return (
        <StrategyWithTimeFrame profileId={id}
                               header={`${strategyId === undefined ? "Add" : "Edit"} Annual Gifting Strategy`}
                               strategyDetailsDescription={strategyId === undefined ? "Annual Gifting" : annualGiftingStrategy!.description}
                               strategyDetailsContributionAmount={strategyId === undefined ? 0 : annualGiftingStrategy!.contributionAmount}
                               timeFrameYearsUntilFlow={strategyId === undefined ? 0 : annualGiftingStrategy!.yearsUntilFlow}
                               timeFrameYearsOfFlow={strategyId === undefined ? 1 : annualGiftingStrategy!.yearsOfFlow}
                               timeFrameYearsOfGrantorStatus={strategyId === undefined ? 0 : annualGiftingStrategy!.yearsOfGrantorStatus}
                               getEstimatedImpact={getEstimatedImpact}
                               initialStateEstimatedImpact={strategyId === undefined ? initialEstimatedImpact : annualGiftingStrategy!.estimatedImpact}
                               handleSaveClick={handleSaveClickAnnualGifting}
                               isAnnualGift={true}
        />
    )
}

export default AddEditAnnualGifting;