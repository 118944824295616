import React, {ChangeEvent, useEffect, useState} from "react";
import {DropdownGroup} from "xps-react"
import {LiabilityType, LoanType, PaymentFrequencyType, PersonalLiabilityFormData,} from "../models/PersonalLiability";
import PercentInput from "../../components/Input/PercentInput";
import {DISPLAY_MONTH_YEAR_FORMAT, ISO8601_DATE_FORMAT} from "../../constants/common";
import moment, {Moment} from "moment";
import {toDisplayDateFormat} from "../../utils/dateUtils";
import {formatCurrency} from "../../utils/format";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectClientAssets, setActiveFormAsset} from "../clientAssetsSlice";
import {booleanToRadioButton, radioButtonToBoolean} from "../formHelpers";
import {RadioYesNoOptional} from "../models/Common";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import DataEntrySummary from "../../components/DataEntry/DataEntrySummary";
import Ownership from "../Ownership";
import {
    CurrencyInputWithLabel,
    DatePicker,
    Dropdown,
    DropdownItem,
    Input,
    RadioGroup,
    RedAsterisk,
    RequiredFieldsBanner,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../components";
import deepEquals from "fast-deep-equal";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {LegalEntityFormData} from "../models/Ownership";
import {extractOwnershipDetailsFormData} from "../Ownership/mappers";
import {isOwnershipPercentageNotEqual100} from "../Ownership/validation";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {HistoryBlockModal} from "../../components/HistoryBlockModal/HistoryBlockModal";
import AlertBanner from "../../components/AlertBanner/AlertBanner";

type PersonalLiabilityFormProps = {
    formatTitle: (description: string) => string,
    defaultPersonalLiability: PersonalLiabilityFormData,
    handleSave: (unsavedPersonalLiability: PersonalLiabilityFormData) => Promise<void>,
    handleCancel: (isFormChanged: boolean) => void,
    memberGroup: MemberGroup,
    legalEntities: LegalEntityFormData[],
    updateLegalEntities: (legalEntities: LegalEntityFormData[]) => void
}

const typeOptions = Object.freeze(["Mortgage", "Line of Credit", "Note Payable", "Other"]);
const typeDropdownItems = typeOptions.map(typeOption => (<DropdownItem key={typeOption}
                                                                       itemText={typeOption}
                                                                       value={typeOption}/>));
const paymentFrequencyOptions = Object.freeze(["Monthly", "Quarterly", "Annually", "On Demand"]);
const paymentFrequencyDropdownOptions = paymentFrequencyOptions.map(paymentFrequencyOption => (
    <DropdownItem key={paymentFrequencyOption}
                  itemText={paymentFrequencyOption}
                  value={paymentFrequencyOption}/>));
const loanTypeOptions = Object.freeze(['Fixed', "Variable"]);
const loanTypeDropdownOptions = loanTypeOptions.map(loanTypeOption => (<DropdownItem key={loanTypeOption}
                                                                                     itemText={loanTypeOption}
                                                                                     value={loanTypeOption}/>));
export default function PersonalLiabilityForm(props: PersonalLiabilityFormProps) {
    const noCollateral = "noCollateral";
    const dispatch = useAppDispatch();

    const {
        personalAssets: {data: personalAssets},
        accounts: {data: standaloneAccounts},
        investmentProgram,
        personalLiabilities
    } = useAppSelector(selectClientAssets)!;

    const {isProfileWithProposalsOrArchived} = useProfileEditableState()

    const defaultOwnership = extractOwnershipDetailsFormData(props.defaultPersonalLiability);
    const [personalLiability, updatePersonalLiability] = useState<PersonalLiabilityFormData>(props.defaultPersonalLiability);
    const [ownershipDetailsFormData, updateOwnershipDetailsFormData] = useState(defaultOwnership);
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(isProfileWithProposalsOrArchived);
    const [isDescriptionInlineErrorShown, setIsDescriptionInlineErrorShown] = useState(false);
    const [isCollateralInlineErrorShown, setIsCollateralInLIneErrorShown] = useState(false);
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    const [isOwnershipPercentageErrorBannerShown, setOwnershipPercentageErrorBannerShown] = useState(false);
    const [showNavigationModal, setShowNavigationModal] = useState(true);
    const [showLoanBalanceSoftWarning, setLoanBalanceSoftWarning] = useState(false);
    const [showAlertBanner, setShowAlertBanner] = useState<boolean>(false);

    useEffect(() => {
        const checkLoanBalance = (value: number, loanBalance: number) => {
            if (loanBalance > value) {
                setShowAlertBanner(true);
                setLoanBalanceSoftWarning(true);
            } else {
                setShowAlertBanner(false);
                setLoanBalanceSoftWarning(false);
            }
        };

        const calculateTotalLoanBalance = (asset: any) => {
            const personalLiabilityArray = personalLiabilities.filter(liability => asset.id === liability.collateralId);
            let totalLoanBalance: number = personalLiability.loanBalance;
            personalLiabilityArray.forEach(function (personalLiabilityItem) {
                if (personalLiabilityItem.id !== personalLiability.id) {
                    totalLoanBalance += personalLiabilityItem.loanBalance;
                }
            })
            return totalLoanBalance;
        }
        const personalAsset = personalAssets.find(asset => asset.id === personalLiability.collateralId);
        if (personalAsset && Object.keys(personalAsset).length > 0) {
            const totalLoanBalance = calculateTotalLoanBalance(personalAsset);
            checkLoanBalance(personalAsset.inEstateValue, totalLoanBalance)
        }

        const standaloneAccount = standaloneAccounts.find(asset => asset.id === personalLiability.collateralId);
        if (standaloneAccount && Object.keys(standaloneAccount).length > 0) {
            const totalLoanBalance = calculateTotalLoanBalance(standaloneAccount);
            checkLoanBalance(standaloneAccount.marketValue.inEstateValue, totalLoanBalance);
        }

        const legalAgreement = investmentProgram ? investmentProgram.legalAgreements.find(asset => asset.id === personalLiability.collateralId) : null;
        if (legalAgreement && Object.keys(legalAgreement).length > 0) {
            const totalLoanBalance = calculateTotalLoanBalance(legalAgreement);
            checkLoanBalance(legalAgreement.marketValue, totalLoanBalance);
        }

        if (personalLiability.collateralId == null) {
            setShowAlertBanner(false);
            setLoanBalanceSoftWarning(false);
        }

    }, [personalLiability.loanBalance, personalLiability.collateralId]);

    const assetSummaryItems = [
        {
            label: 'Description',
            value: personalLiability.description
        },
        {
            label: 'Institution',
            value: personalLiability.institution
        },
        {
            label: 'Interest Rate',
            value: `${personalLiability.interestRate}%`
        },
        {
            label: 'Maturity Date',
            value: toDisplayDateFormat(personalLiability.maturityDate, DISPLAY_MONTH_YEAR_FORMAT)
        },
        {
            label: 'Loan Balance',
            value: formatCurrency(!isNaN(personalLiability.loanBalance) ? personalLiability.loanBalance : 0)
        }
    ];

    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [personalLiability.description]);

    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [personalLiability.collateralId]);

    useEffect(() => {
        dispatch(setActiveFormAsset({
            assetType: 'liability',
            id: personalLiability.id,
            description: personalLiability.description,
            inEstateValue: isNaN(personalLiability.loanBalance) ? 0 : personalLiability.loanBalance,
            hasInEstateOwnership: true
        }));
        return clearActiveFormAsset;
    }, [personalLiability.description, personalLiability.loanBalance])

    useEffect(() => {
        setOwnershipPercentageErrorBannerShown(isOwnershipPercentageErrorBannerShown &&
            isOwnershipPercentageNotEqual100(ownershipDetailsFormData));
    }, [ownershipDetailsFormData.legalEntityOwnerships, ownershipDetailsFormData.memberOwnerships]);

    const clearActiveFormAsset = () => {
        dispatch(setActiveFormAsset(null));
    }

    const isAnyRequiredFieldEmpty = () => {
        const isDescriptionEmpty = !personalLiability.description.trim();
        let isCollateralEmpty
        if (personalLiability.liabilityType !== "Other") {
            isCollateralEmpty = !personalLiability.collateralId?.trim();
        } else {
            isCollateralEmpty = false;
        }

        setIsCollateralInLIneErrorShown(isCollateralEmpty);
        setIsDescriptionInlineErrorShown(isDescriptionEmpty);

        const isOwnershipDataMissing = ownershipDetailsFormData.legalEntityOwnerships.some((ownership) => {
            return !ownership.name.trim() || !ownership.type;
        });

        return isDescriptionEmpty || isCollateralEmpty || isOwnershipDataMissing;
    }

    const handleSaveButton = async () => {
        if (validateForm()) {
            updateSaveButtonDisabled(true);
            setShowNavigationModal(false);

            const liability: PersonalLiabilityFormData = {
                ...personalLiability,
                ...ownershipDetailsFormData
            };

            await props.handleSave(liability);
            return true;
        }
        return false;
    }

    const validateForm = () => {
        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();
        if (isRequiredFieldEmpty) {
            setRequiredFieldsBannerShown(true);
        }

        const isOwnershipPercentageInvalid = isOwnershipPercentageNotEqual100(ownershipDetailsFormData);
        if (isOwnershipPercentageInvalid) {
            setOwnershipPercentageErrorBannerShown(true);
        }

        return !isRequiredFieldEmpty && !isOwnershipPercentageInvalid;
    };

    const isFormChanged = () => {
        const updated = {
            ...personalLiability,
            ...ownershipDetailsFormData
        };
        const initial = {
            ...props.defaultPersonalLiability,
            ...defaultOwnership
        }
        return !deepEquals(initial, updated);
    }

    const onCancelClick = () => {
        setShowNavigationModal(false);
        props.handleCancel(isFormChanged());
    }

    return (
        <div className='personal-liability'>
            <HistoryBlockModal
                when={isFormChanged() && showNavigationModal}
                itemType={'page'}
                onSave={handleSaveButton}
            />
            <DataEntryHeader
                className='dataEntryHeader'
                title={props.formatTitle(personalLiability.description)}
                onPrimaryButtonClick={handleSaveButton}
                disablePrimaryButton={isSaveButtonDisabled}
                primaryButtonText='Save'
                secondaryButtonText='Cancel'
                onSecondaryButtonClick={onCancelClick}
            />
            <div className="marginbottom-md alert-banner-container">
                <AlertBanner
                    className={""}
                    icon="info"
                    type="warning"
                    showAlert={showAlertBanner}
                    showCloseBtn={true}
                    message={"Liabilities exceed the present value of collateral."}
                    onClose={() => setShowAlertBanner(false)}
                />
            </div>
            <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} itemType="liability"/>
            <div className='personal-asset__form layout-data-entry-form'>
                <article>
                    <section>
                        <UnderlinedHeader
                            className="asset-details-section-header"
                            primaryText="Liability Details"
                            secondaryContent={<RequiredFieldsSubheader/>}
                        />

                        <RadioGroup
                            id="liabilityType"
                            name="liabilityType"
                            label="Liability Type"
                            layout="horizontal"
                            disabled={personalAssets.length === 0 || isProfileWithProposalsOrArchived}
                            values={["Personal", "Other"]}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const {value} = e.target;
                                updatePersonalLiability({
                                    ...personalLiability,
                                    liabilityType: value as LiabilityType,
                                    collateralId: null,
                                })
                                setRequiredFieldsBannerShown(false)
                                setIsCollateralInLIneErrorShown(false)
                                setIsDescriptionInlineErrorShown(false)
                            }}
                            selected={personalLiability.liabilityType}
                        />

                        <div className="layout-data-entry-form__field">
                            <label id="type">
                                <b>Type</b>
                            </label>
                            <Dropdown
                                className="typeField"
                                name="type"
                                id="typeInput"
                                aria-label="type"
                                aria-labelledby="type"
                                size="medium"
                                value={personalLiability.type}
                                disabled={isProfileWithProposalsOrArchived}
                                onChange={({value}) => {
                                    updatePersonalLiability({
                                        ...personalLiability,
                                        type: value,
                                        description: value
                                    })
                                }}
                            >
                                {typeDropdownItems}
                            </Dropdown>
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label className="descriptionLabel" id="descriptionFieldInput-label">
                                <b>Description<RedAsterisk/></b>
                            </label>
                            <Input
                                aria-labelledby="descriptionFieldInput-label"
                                value={personalLiability.description}
                                readOnly={isProfileWithProposalsOrArchived}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    updatePersonalLiability({
                                        ...personalLiability,
                                        description: e.target.value
                                    })
                                }}
                                onBlur={() => setIsDescriptionInlineErrorShown(!personalLiability.description.trim())}
                                error={isDescriptionInlineErrorShown ? "Description is required." : undefined}
                            />

                        </div>
                        <div className="layout-data-entry-form__field">
                            <label id="collateral">
                                <b>Collateral<RedAsterisk/></b>
                            </label>
                            <div style={{whiteSpace: "pre", width: "140px"}}>
                                <Dropdown
                                    id="collateralInput"
                                    name="collateral"
                                    aria-label="collateral"
                                    aria-labelledby="collateral"
                                    size="medium"
                                    defaultText="Select..."
                                    value={personalLiability.liabilityType === "Other" && personalLiability.collateralId === null
                                        ? noCollateral : personalLiability.collateralId}
                                    disabled={isProfileWithProposalsOrArchived}
                                    onChange={({value}) => {
                                        updatePersonalLiability({
                                            ...personalLiability,
                                            collateralId: value === noCollateral ? null : value,
                                        });
                                    }}
                                    onBlur={() => setIsCollateralInLIneErrorShown(!personalLiability.collateralId?.trim())}
                                    error={(isCollateralInlineErrorShown
                                        && personalLiability.liabilityType !== "Other") ?
                                        "Collateral is required." : undefined}
                                >
                                    {personalLiability.liabilityType === 'Personal' ?
                                        personalAssets.map(personalAsset =>
                                            (<DropdownItem
                                                key={personalAsset.id}
                                                itemText={`${personalAsset.description}: ${formatCurrency(personalAsset.inEstateValue)}`}
                                                value={personalAsset.id}/>)) :
                                        [
                                            investmentProgram && investmentProgram.legalAgreements.length > 0
                                            && <DropdownGroup key="investment-program-option-group"
                                                              groupName={investmentProgram.name}>
                                                {investmentProgram.legalAgreements.map(legalAgreement =>
                                                    (<DropdownItem
                                                        key={legalAgreement.id}
                                                        itemText={`${legalAgreement.name}: ${formatCurrency(legalAgreement.marketValue ?? undefined)}`}
                                                        value={legalAgreement.id}/>))}
                                            </DropdownGroup>,
                                            <DropdownGroup key="standalone-accounts-option-group"
                                                           groupName="Standalone Accounts">
                                                {standaloneAccounts.map(standaloneAccount =>
                                                    (<DropdownItem
                                                        key={standaloneAccount.id}
                                                        itemText={`${standaloneAccount.name}: ${formatCurrency(standaloneAccount.inEstateValue ?? undefined)}`}
                                                        value={standaloneAccount.id}
                                                    />))}
                                            </DropdownGroup>,
                                            <DropdownItem
                                                key="no-collateral-option"
                                                itemText="None"
                                                value={noCollateral}/>
                                        ]
                                    }
                                </Dropdown>
                            </div>
                        </div>
                        <CurrencyInputWithLabel
                            label="Loan Balance"
                            value={personalLiability.loanBalance}
                            maxLength={16}
                            readOnly={isProfileWithProposalsOrArchived}
                            className={"currencyInputLoanBalance"}
                            description={showLoanBalanceSoftWarning ? "Your liabilities have exceeded the value of collateral." : undefined}
                            onChangeValue={(_e, value) => {
                                updatePersonalLiability({
                                    ...personalLiability,
                                    loanBalance: value as number,
                                });
                            }}
                            onBlur={() => {
                                if (isNaN(personalLiability.loanBalance)) {
                                    updatePersonalLiability({
                                        ...personalLiability,
                                        loanBalance: 0
                                    });
                                }
                            }}
                        />

                        <div className="layout-data-entry-form__field">
                            <label className="institutionLabel" id="institutionFieldInput-label">
                                <b>Institution</b>
                            </label>
                            <Input
                                aria-labelledby="institutionFieldInput-label"
                                value={personalLiability.institution}
                                readOnly={isProfileWithProposalsOrArchived}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    updatePersonalLiability({
                                        ...personalLiability,
                                        institution: e.target.value
                                    })
                                }}
                            />
                        </div>

                        <div className="layout-data-entry-form__field">
                            <label id="paymentFrequency">
                                <b>Payment Frequency</b>
                            </label>
                            <Dropdown
                                className="paymentFrequencyField"
                                name="paymentFrequency"
                                id="paymentFrequencyInput"
                                aria-label="paymentFrequency"
                                aria-labelledby="paymentFrequency"
                                size="medium"
                                value={personalLiability.paymentFrequency}
                                disabled={isProfileWithProposalsOrArchived}
                                onChange={({value}: { value: PaymentFrequencyType }) => {
                                    updatePersonalLiability({
                                        ...personalLiability,
                                        paymentFrequency: value,
                                    })
                                }}
                            >
                                {paymentFrequencyDropdownOptions}
                            </Dropdown>
                        </div>

                        <div className="layout-data-entry-form__field">
                            <label id="loanType">
                                <b>Loan Type</b>
                            </label>
                            <Dropdown
                                className="loanTypeField"
                                name="loanType"
                                id="loanTypeInput"
                                aria-label="loanType"
                                aria-labelledby="loanType"
                                size="medium"
                                value={personalLiability.loanType}
                                disabled={isProfileWithProposalsOrArchived}
                                onChange={({value}: { value: LoanType }) => {
                                    updatePersonalLiability({
                                        ...personalLiability,
                                        loanType: value,
                                    })
                                }}
                            >
                                {loanTypeDropdownOptions}
                            </Dropdown>
                        </div>
                        <RadioGroup
                            id="interestOnly"
                            name="interestOnly"
                            label="Interest Only"
                            layout="horizontal"
                            values={["Yes", "No"]}
                            disabled={isProfileWithProposalsOrArchived}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const {value} = e.target;
                                updatePersonalLiability({
                                    ...personalLiability,
                                    interestOnly: radioButtonToBoolean(value as RadioYesNoOptional),
                                })
                            }}
                            selected={booleanToRadioButton(personalLiability.interestOnly)}
                        />
                        <div className="layout-data-entry-form__field interest-rate">
                            <PercentInput defaultValue={"0"}
                                          value={personalLiability.interestRate}
                                          label="Interest Rate"
                                          minValue={0}
                                          maxValue={100}
                                          disabled={isProfileWithProposalsOrArchived}
                                          onChange={value => {
                                              updatePersonalLiability({
                                                  ...personalLiability,
                                                  interestRate: value,
                                              })
                                          }}
                            />
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label className="maturityDateLabel">
                                <b>Maturity Date</b>
                            </label>
                            <div style={{position: 'relative'}}>
                                <DatePicker
                                    className="maturityDateInput"
                                    id="maturityDateInput"
                                    displayFormat={DISPLAY_MONTH_YEAR_FORMAT}
                                    hideKeyboardShortcutsPanel
                                    date={personalLiability.maturityDate ? moment(personalLiability.maturityDate) : undefined}
                                    disabled={isProfileWithProposalsOrArchived}
                                    onDateChange={
                                        (date: Moment) => {
                                            const maturityDate = date?.utc().format(ISO8601_DATE_FORMAT);
                                            updatePersonalLiability({
                                                ...personalLiability,
                                                maturityDate,
                                            });
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </section>
                    <section>
                        <Ownership
                            onFormDataChange={updateOwnershipDetailsFormData}
                            formData={ownershipDetailsFormData}
                            totalAssetValue={personalLiability.loanBalance}
                            isOwnershipPercentageErrorBannerShown={isOwnershipPercentageErrorBannerShown}
                            memberGroup={props.memberGroup}
                            legalEntities={props.legalEntities}
                            updateLegalEntities={props.updateLegalEntities}
                            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                        />
                    </section>
                </article>
                <aside data-testid="asset-summary" aria-label="Asset Summary">
                    <DataEntrySummary
                        items={assetSummaryItems}
                        title="Asset Summary"
                    />
                </aside>
            </div>
        </div>
    )
}
