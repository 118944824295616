import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react"
import {wealthPOApiClient} from "../WealthPOApiClient";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {
    CurrencyInput,
    EmptyStateContainer,
    Icon,
    Input,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../components";
import DataEntrySummary, {DataEntrySummaryItem} from "../../components/DataEntry/DataEntrySummary";
import {YearsInput} from "../../components/YearsInput/YearsInput";
import RangeInputs, {RangeInputProp} from "../../components/RangeInputs/RangeInputs";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {EstimatedImpact, FailedAPICall, GrowthRates, StrategyTimeFrame, StrategyType} from "../WealthPOTypes";
import {formatCurrency} from "../../utils/format";
import {
    CreateOrEditStrategyParameters,
    DateAndAgeValues,
    getDateAndAgeValues,
    PlanningPeriodValues,
    processInvestorGroupResponse,
    validateEndAge,
    validateEndYear,
    validateStartAge,
    validateStartYear,
    validateYearsOfFlow,
    validateYearsUntilFlow
} from "../WealthPOUtils";
import {useHistory} from "react-router-dom";
import DiscardModal from "../../components/DiscardModal/DiscardModal";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../components/Modal/Error/GenericErrorModal";
import AlertBanner from "../../components/AlertBanner/AlertBanner";
import useProfileEditableState from "../../hooks/useProfileEditableState";

export interface StrategyWithTimeFrameProps {
    profileId: string,
    header: string,
    strategyDetailsDescription: string,
    strategyDetailsContributionAmount: number,
    timeFrameYearsUntilFlow: number,
    timeFrameYearsOfFlow: number,
    timeFrameYearsOfGrantorStatus: number,
    getEstimatedImpact: (requestBody: StrategyTimeFrame, signal?: AbortSignal) => Promise<EstimatedImpact>
    initialStateEstimatedImpact: EstimatedImpact;
    handleSaveClick: (requestParameters: CreateOrEditStrategyParameters) => void,
    isAnnualGift: boolean
}

const enum InputTypes {
    YEARS_UNTIL_FLOW = "yearsUntilFlow",
    YEARS_OF_FLOW = "yearsOfFlow",
    STARTING_STRATEGY_YEAR = "startingStrategyYear",
    ENDING_STRATEGY_YEAR = "endingStrategyYear",
    STARTING_AGE = "startingClientAge",
    ENDING_AGE = "endingClientAge"
}


const StrategyWithTimeFrame: React.FC<StrategyWithTimeFrameProps> = ({
                                                                         profileId,
                                                                         header,
                                                                         strategyDetailsDescription,
                                                                         strategyDetailsContributionAmount,
                                                                         timeFrameYearsUntilFlow,
                                                                         timeFrameYearsOfFlow,
                                                                         timeFrameYearsOfGrantorStatus,
                                                                         getEstimatedImpact,
                                                                         initialStateEstimatedImpact,
                                                                         handleSaveClick,
                                                                         isAnnualGift
                                                                     }) => {
    const history = useHistory();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const [description, setDescription] = useState<string>(strategyDetailsDescription);
    const [contributionAmount, setContributionAmount] = useState<number>(strategyDetailsContributionAmount);
    const [contributionAmountDisplayValue, setContributionAmountDisplayValue] = useState<number>(strategyDetailsContributionAmount);
    const [riskAssetGrowthRate, setRiskAssetGrowthRate] = useState<string>("");
    const [yearsUntilFlow, setYearsUntilFlow] = useState<number>(timeFrameYearsUntilFlow);
    const [yearsUntilFlowDisplayValue, setYearsUntilFlowDisplayValue] = useState<number>(timeFrameYearsUntilFlow)
    const [yearsOfFlow, setYearsOfFlow] = useState<number>(timeFrameYearsOfFlow);
    const [yearsOfFlowDisplayValue, setYearsOfFlowDisplayValue] = useState<number>(timeFrameYearsOfFlow)
    const [yearsOfGrantorStatus, setYearsOfGrantorStatus] = useState<number>(timeFrameYearsOfGrantorStatus);
    const [yearsOfGrantorStatusDisplayValue, setYearsOfGrantorStatusDisplayValue] = useState<number>(timeFrameYearsOfGrantorStatus);
    const [startDate, setStartDate] = useState<string>("")
    const [endDate, setEndDate] = useState<string>("")
    const [startYearDisplayValue, setStartYearDisplayValue] = useState<number | string>("")
    const [endYearDisplayValue, setEndYearDisplayValue] = useState<number | string>("")
    const [startAgeDisplayValue, setStartAgeDisplayValue] = useState<number | string>("")
    const [endAgeDisplayValue, setEndAgeDisplayValue] = useState<number | string>("")
    const [planningPeriodValues, setPlanningPeriodValues] = useState<PlanningPeriodValues>({
        birthdate: "",
        firstName: "",
        planningPeriod: undefined
    })
    const [dateAndAgeValues, setDateAndAgeValues] = useState<DateAndAgeValues>({
        startDate: "",
        endDate: "",
        startYear: "",
        endYear: "",
        startAge: "",
        endAge: ""
    })
    const [estimatedImpact, setEstimatedImpact] = useState<EstimatedImpact>(initialStateEstimatedImpact)
    const [initialFormUpdated, setInitialFormUpdated] = useState<boolean>(false);
    const [errorFieldToRecalculateTimeFrame, setErrorFieldToRecalculateTimeFrame] = useState<InputTypes | undefined>(undefined);
    const [errorOnSave, setErrorOnSave] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [failedApiCall, setFailedApiCall] = useState<FailedAPICall>();
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false);

    const [initialValues, setInitialValues] = useState({
        startYear: "",
        endYear: "",
        startAge: "",
        endAge: ""
    })

    const estimatedImpactAbortControllerRef = useRef<AbortController | undefined>(undefined)

    const validateAndRecomputeValues = (changedInputField: InputTypes, inputValue: any, originalValue: number | string, setDisplayValue: (value: number) => void) => {
        setErrorFieldToRecalculateTimeFrame(undefined)

        if (isNaN(parseInt(inputValue))) {
            setDisplayValue(Number(originalValue));
            return;
        }

        let newValue: number = Number(inputValue);

        let validationResponse = {
            errorPresent: false,
            newYearsUntilFlow: yearsUntilFlow,
            newYearsOfFlow: yearsOfFlow
        }

        switch (changedInputField) {
            case InputTypes.YEARS_UNTIL_FLOW:
                validationResponse = validateYearsUntilFlow(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues);
                break;
            case InputTypes.YEARS_OF_FLOW:
                validationResponse = validateYearsOfFlow(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues)
                break;
            case InputTypes.STARTING_STRATEGY_YEAR:
                validationResponse = validateStartYear(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            case InputTypes.ENDING_STRATEGY_YEAR:
                validationResponse = validateEndYear(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            case InputTypes.STARTING_AGE:
                validationResponse = validateStartAge(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            case InputTypes.ENDING_AGE:
                validationResponse = validateEndAge(newValue, yearsUntilFlow, yearsOfFlow, planningPeriodValues, dateAndAgeValues)
                break;
            default:
                setErrorFieldToRecalculateTimeFrame(undefined)
                break;
        }

        if (validationResponse.errorPresent) {
            setErrorFieldToRecalculateTimeFrame(changedInputField)

            if (changedInputField === InputTypes.YEARS_UNTIL_FLOW) {
                setDisplayValue(yearsUntilFlow);
            } else if (changedInputField === InputTypes.YEARS_OF_FLOW) {
                setDisplayValue(yearsOfFlow);
            } else {
                setDisplayAndRecomputedValues(dateAndAgeValues);
            }
        }

        if (validationResponse.newYearsUntilFlow !== yearsUntilFlow) {
            setYearsUntilFlowDisplayValue(validationResponse.newYearsUntilFlow)
            setYearsUntilFlow(validationResponse.newYearsUntilFlow)
            return;
        }

        if (validationResponse.newYearsOfFlow !== yearsOfFlow) {
            setYearsOfFlowDisplayValue(validationResponse.newYearsOfFlow)
            setYearsOfFlow(validationResponse.newYearsOfFlow)
            return;
        }
    }

    const setDisplayAndRecomputedValues = (dateAndAgeComputedValues: DateAndAgeValues) => {
        setStartDate(dateAndAgeComputedValues.startDate)
        setEndDate(dateAndAgeComputedValues.endDate)
        setStartYearDisplayValue(dateAndAgeComputedValues.startYear)
        setEndYearDisplayValue(dateAndAgeComputedValues.endYear)
        setStartAgeDisplayValue(dateAndAgeComputedValues.startAge)
        setEndAgeDisplayValue(dateAndAgeComputedValues.endAge)
        setDateAndAgeValues(dateAndAgeComputedValues);
    }

    const validate = (): boolean => {
        setErrorOnSave(false);

        let isValid = true;

        if (description === undefined || description === "") {
            setErrorOnSave(true)
            isValid = false;
        }

        return isValid;
    }

    const handleSaveButtonClick = () => {
        if (validate()) {
            let requestParameters = {
                description,
                contributionAmount,
                yearsUntilFlow,
                yearsOfFlow,
                yearsOfGrantorStatus
            } as CreateOrEditStrategyParameters;

            handleSaveClick(requestParameters);
        }
    };

    const handleModalDiscardButton = () => {
        setShowCancelModal(false);
        history.push(`/Profile/${profileId}/ClientProfile/WealthPlanOptimizer/Summary`);
    }

    const handleCancelButtonClick = () => {
        const isFormChanged = description !== strategyDetailsDescription
            || contributionAmount !== strategyDetailsContributionAmount
            || yearsUntilFlow !== timeFrameYearsUntilFlow
            || yearsOfFlow !== timeFrameYearsOfFlow
            || yearsOfGrantorStatus !== timeFrameYearsOfGrantorStatus
            || dateAndAgeValues.startYear !== initialValues.startYear
            || dateAndAgeValues.endYear !== initialValues.endYear
            || dateAndAgeValues.startAge !== initialValues.startAge
            || dateAndAgeValues.endAge !== initialValues.endAge

        if (isFormChanged) {
            setShowCancelModal(true);
        } else {
            history.push(`/Profile/${profileId}/ClientProfile/WealthPlanOptimizer/Summary`)
        }
    }

    const fetchReferenceDataAndInvestorGroup = async () => {
        const apiRequests: [Promise<GrowthRates>, Promise<InvestorGroupType>] = [
            wealthPOApiClient.getGrowthRatesReferenceData(profileId),
            clientManagementApiClient.getInvestorGroup(profileId)
        ]

        try {
            let [growthRates, investorGroup] = await Promise.all(apiRequests);

            const riskAssetRateDto = growthRates.rateDtos.find(rateDto => rateDto.id === "RiskAsset");
            if (riskAssetRateDto) {
                const growthRate = riskAssetRateDto.value * 100;
                const s = growthRate.toPrecision(3);
                setRiskAssetGrowthRate(s)
            }

            const {
                planningPeriodComputedValues,
                dateAndAgeComputedValues
            } = processInvestorGroupResponse(investorGroup, yearsUntilFlow, yearsOfFlow);

            setInitialValues({
                startYear: dateAndAgeComputedValues.startYear.toString(),
                endYear: dateAndAgeComputedValues.endYear.toString(),
                startAge: dateAndAgeComputedValues.startAge.toString(),
                endAge: dateAndAgeComputedValues.endAge.toString()
            })

            setPlanningPeriodValues(planningPeriodComputedValues);
            setDisplayAndRecomputedValues(dateAndAgeComputedValues);
        } catch (err) {
            openErrorModal(err);
        }
    }

    const fetchEstimatedImpact = async () => {

        estimatedImpactAbortControllerRef.current = undefined;

        if (isAnnualGift && initialFormUpdated) {
            estimatedImpactAbortControllerRef.current = new AbortController();
            const {signal} = estimatedImpactAbortControllerRef.current;

            getEstimatedImpact({
                strategyId: undefined,
                strategyType: StrategyType.ANNUAL_GIFTING,
                contributionAmount: contributionAmount,
                yearsUntilFlow,
                yearsOfFlow,
                yearsOfGrantorStatus
            }, signal)
                .then(estimatedImpactResponse => {
                    setEstimatedImpact(estimatedImpactResponse);
                })
                .catch((err) => {
                    setFailedApiCall(FailedAPICall.ESTIMATED_IMPACT);
                    openErrorModal(err);
                });
        }
    }

    const openErrorModal = (error: Error | any) => {
        setGenericError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: profileId,
            time: new Date(),
            errorDetail: `Failed to load ${header} page (${error.status})`,
            operationId: error.headers.get('trace-id')
        })
    }

    const closeErrorModal = () => {
        setGenericError({...genericError, isOpen: false});
    };

    const handleRetryClickInCommunicationErrorModal = () => {
        closeErrorModal();

        if (failedApiCall === FailedAPICall.ESTIMATED_IMPACT) {
            fetchEstimatedImpact().then();
        } else {
            fetchReferenceDataAndInvestorGroup().then();
        }
    }

    const handleCloseClickInCommunicationErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true);
    }

    useEffect(() => {
        fetchReferenceDataAndInvestorGroup().then();
    }, [profileId])

    useEffect(() => {
        let dateAndAgeComputedValues: DateAndAgeValues = getDateAndAgeValues(planningPeriodValues.birthdate, yearsUntilFlow, yearsOfFlow);
        setDisplayAndRecomputedValues(dateAndAgeComputedValues);
    }, [yearsUntilFlow, yearsOfFlow])

    useEffect(() => {
        fetchEstimatedImpact().then();

        return () => {
            if (estimatedImpactAbortControllerRef.current) {
                estimatedImpactAbortControllerRef.current.abort();
            }
        }
    }, [contributionAmount, yearsOfFlow, yearsUntilFlow, initialFormUpdated])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setErrorFieldToRecalculateTimeFrame(undefined);
        }, 6000);
        return () => {
            clearTimeout(timeout);
        }
    }, [errorFieldToRecalculateTimeFrame]);

    const memoizedErrorMessage = useMemo(() => {
        return errorFieldToRecalculateTimeFrame && (
            <>
                <div></div>
                <div data-testid={`error-message-${errorFieldToRecalculateTimeFrame}`}
                     className="strategy-time-frame-error-msg">
                    Strategy Time Frame was adjusted to match Planning Period.
                </div>
            </>
        )
    }, [errorFieldToRecalculateTimeFrame])

    const memoizedEstimatedImpact: DataEntrySummaryItem[] = useMemo(() => {
        return [
            {
                label: "Amount to Beneficiaries",
                value: (estimatedImpact.amountToBeneficiaries !== undefined) ? formatCurrency(estimatedImpact.amountToBeneficiaries) : undefined,
                testId: "amountToBeneficiaries",
            } as DataEntrySummaryItem,
            {
                label: "Est. Estate Tax",
                value: (estimatedImpact.impactToEstEstateTax !== undefined) ? formatCurrency(estimatedImpact.impactToEstEstateTax) : undefined,
                testId: "impactToEstEstateTax",
            } as DataEntrySummaryItem,
        ]
    }, [estimatedImpact]);

    const memoizedStrategySummary: DataEntrySummaryItem[] = useMemo(() => {
        return [
            {
                label: isAnnualGift ? "Annual Flow" : "One-Time Gift",
                value: formatCurrency(contributionAmount),
                testId: "strategySummaryContributionAmount"
            } as DataEntrySummaryItem,
            ...(isAnnualGift
                    ? [{
                        label: "Years until Flow",
                        value: yearsUntilFlow,
                        testId: "strategySummaryYearsUntilFlow"
                    } as DataEntrySummaryItem]
                    : []
            ),
            (isAnnualGift ? {
                label: "Years of Flow",
                value: yearsOfFlow,
                testId: "strategySummaryYearsOfFlow"
            } : {
                label: "Years of Grantor Status",
                value: yearsOfGrantorStatus,
                testId: "strategySummaryYearsOfGrantorStatus"
            } as DataEntrySummaryItem)
        ]
    }, [contributionAmount, yearsUntilFlow, yearsOfFlow, yearsOfGrantorStatus])

    if (genericError.isOpen) {
        return (
            <GenericErrorModal
                errorModalData={genericError}
                onClickButton={handleRetryClickInCommunicationErrorModal}
                onRequestClose={handleCloseClickInCommunicationErrorModal}
                buttonText="Retry"
                buttonProps={
                    {
                        primary: true,
                        className: 'full-width center-align',
                        iconPosition: 'left',
                        iconName: 'refresh'
                    }
                }
                showAlertIcon={false}
            />
        )
    }

    if (showTempErrorMessage) {
        return (
            <EmptyStateContainer
                className="no-wpo-summary-placeholder"
                title="Unable to Load This Page"
                size="large"
                description="Try again later."
            />
        )
    }

    return (
        <ScrollableContainer id={"add-edit-annual-gifting-scroll-container"} className="wealth-plan-optimizer">
            <DataEntryHeader title={header}
                             primaryButtonText={"SAVE"}
                             secondaryButtonText={"CANCEL"}
                             disablePrimaryButton={isProfileWithProposalsOrArchived}
                             onPrimaryButtonClick={handleSaveButtonClick}
                             onSecondaryButtonClick={handleCancelButtonClick}
            />

            <div className="marginbottom-md __alert-banner-container">
                <AlertBanner showAlert={errorOnSave}>
                    Required fields missing. All required fields must be entered to save a strategy.
                </AlertBanner>
            </div>

            <div className="wealthpo-strategy__form">
                <article>
                    <section aria-label="Strategy Details">
                        <UnderlinedHeader
                            className={"wealthpo-strategy__underlined-header"}
                            primaryText="Strategy Details"
                            rightAlignedContent={<RequiredFieldsSubheader/>}
                        />
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={'description'}>Description<RedAsterisk/></label>
                            <Input
                                name="descriptionField"
                                aria-label="description"
                                aria-labelledby="descriptionFieldInput-label"
                                id="descriptionFieldInput"
                                removeMarginTop
                                size="medium"
                                type="text"
                                value={description}
                                error={errorOnSave ? "Description is required." : undefined}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                            />
                        </div>
                        <div className="layout-data-entry-form__field" style={{alignItems: "start"}}>
                            <label className={"h5"}
                                   data-testid={'contributionAmount'}>{isAnnualGift ? "Annual Flow" : "One-Time Gift"}</label>
                            <div className="annual-flow-input-field">
                                <CurrencyInput
                                    name="contributionAmountField"
                                    aria-label="contributionAmount"
                                    id="contributionAmountFieldInput"
                                    size="medium"
                                    value={contributionAmountDisplayValue}
                                    textAlign='left'
                                    onChangeValue={e => isNaN(parseInt(e.target.value)) ? setContributionAmountDisplayValue(0) : setContributionAmountDisplayValue(parseInt(e.target.value))}
                                    onBlur={() => {
                                        setContributionAmount(contributionAmountDisplayValue);
                                        if (!initialFormUpdated) {
                                            setInitialFormUpdated(true);
                                        }
                                    }}
                                />
                                {isAnnualGift &&
                                    <span
                                        className={"annual-flow-subheader"}>Maximum $18,000 per year per beneficiary</span>
                                }
                            </div>
                        </div>

                        {!isAnnualGift &&
                            <div className="layout-data-entry-form__field">
                                <label className={"h5"} data-testid={"yearsOfGrantorStatus"}>Years of Grantor
                                    Status</label>
                                <div data-testid={"yearsOfGrantorStatusInput"}>
                                    <YearsInput style={{width: "90px"}}
                                                name={"yearsOfGrantorStatusField"}
                                                className={"years-of-grantor-status"}
                                                value={yearsOfGrantorStatusDisplayValue}
                                                size={"medium"}
                                                onBlur={() => {
                                                    setYearsOfGrantorStatus(yearsOfGrantorStatusDisplayValue)
                                                    if (!initialFormUpdated) {
                                                        setInitialFormUpdated(true);
                                                    }
                                                }}
                                                readOnly={false}
                                                disabled={false}
                                                onChangeValue={(_e, value) => {
                                                    setYearsOfGrantorStatusDisplayValue(value)
                                                }}
                                                aria-label={"yearsOfGrantorStatus"}
                                    />
                                </div>
                            </div>
                        }

                        <div className="layout-data-entry-form__field">
                            <label className={"h5"}>Growth Rate</label>
                            <div className="growth-rate-value">
                                <span aria-label={'growthRate'}>Triple Net Risk Asset ({riskAssetGrowthRate}%)</span>
                            </div>
                        </div>
                    </section>

                    {isAnnualGift &&
                        <section aria-label="Strategy Time Frame">
                            <UnderlinedHeader
                                className={"wealthpo-strategy__underlined-header"}
                                primaryText="Strategy Time Frame"
                                secondaryContent={
                                    <div data-testid="strategyTimeFrameInfoIcon">
                                        <Icon className="info-icon popover-trigger popoverContainer" ariaHidden={false}
                                              name="status_info_outline" type="info"/>
                                    </div>
                                }
                            />
                            <div className="layout-data-entry-form__field">
                                <label className={"h5"} data-testid={"yearsUntilFlow"}>Years until Flow</label>
                                <div className="display-flex" data-testid="strategyTimeFrameYearsUntilFlow">
                                    <YearsInput style={{width: "134px"}}
                                                name={"yearsUntilFlow"}
                                                aria-label={"yearsUntilFlow"}
                                                className={"StrategyWithTimeFrameYearsUntilFlow"}
                                                value={yearsUntilFlowDisplayValue}
                                                size={"medium"}
                                                onBlur={() => {
                                                    validateAndRecomputeValues(InputTypes.YEARS_UNTIL_FLOW, yearsUntilFlowDisplayValue, yearsUntilFlow, setYearsUntilFlowDisplayValue);
                                                    if (!initialFormUpdated) {
                                                        setInitialFormUpdated(true);
                                                    }
                                                }}
                                                readOnly={false}
                                                disabled={false}
                                                onChangeValue={(_e, value) => {
                                                    setYearsUntilFlowDisplayValue(value)
                                                }}
                                    />
                                    <div className="strategyTimeFrameDate"
                                         data-testid="strategyTimeFrameYearsUntilFlowStartDate">
                                        <label>
                                            <b>Start Date</b>
                                        </label>
                                        <span role="startDate"
                                              aria-label="start-date">{startDate}</span>
                                    </div>
                                </div>
                            </div>
                            {(errorFieldToRecalculateTimeFrame && errorFieldToRecalculateTimeFrame === InputTypes.YEARS_UNTIL_FLOW) && memoizedErrorMessage}
                            <div className="layout-data-entry-form__field">
                                <label className={"h5"} data-testid={"yearsOfFlow"}>Years of Flow</label>
                                <div className="display-flex" data-testid="strategyTimeFrameYearsOfFlow">
                                    <YearsInput style={{width: "134px"}}
                                                name={"yearsOfFlow"}
                                                aria-label={"yearsOfFlow"}
                                                className={"StrategyWithTimeFrameYearsOfFlow"}
                                                value={yearsOfFlowDisplayValue}
                                                size={"medium"}
                                                onBlur={() => {
                                                    validateAndRecomputeValues(InputTypes.YEARS_OF_FLOW, yearsOfFlowDisplayValue, yearsOfFlow, setYearsOfFlowDisplayValue);
                                                    if (!initialFormUpdated) {
                                                        setInitialFormUpdated(true);
                                                    }
                                                }}
                                                readOnly={false}
                                                disabled={false}
                                                onChangeValue={(_e, value) => {
                                                    setYearsOfFlowDisplayValue(value)
                                                }}
                                    />
                                    <div className="strategyTimeFrameDate"
                                         data-testid="strategyTimeFrameYearsOfFlowEndDate">
                                        <label>
                                            <b>End Date</b>
                                        </label>
                                        <span role="endDate"
                                              aria-label="end-date">{endDate}</span>
                                    </div>
                                </div>
                            </div>
                            {(errorFieldToRecalculateTimeFrame && errorFieldToRecalculateTimeFrame === InputTypes.YEARS_OF_FLOW) && memoizedErrorMessage}
                            <div className="layout-data-entry-form__field" data-testid="strategyTimeFrameStrategyYears">
                                <label className={"h5"}>Strategy Years</label>
                                <RangeInputs
                                    left={{
                                        value: startYearDisplayValue,
                                        ariaLabel: "startYear",
                                        onChange: (e => setStartYearDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                        readOnly: false,
                                        onBlur: (() => validateAndRecomputeValues(InputTypes.STARTING_STRATEGY_YEAR, startYearDisplayValue, dateAndAgeValues.startYear, setStartYearDisplayValue))
                                    } as RangeInputProp}
                                    right={{
                                        value: endYearDisplayValue,
                                        ariaLabel: "endYear",
                                        onChange: (e => setEndYearDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                        readOnly: false,
                                        onBlur: (() => validateAndRecomputeValues(InputTypes.ENDING_STRATEGY_YEAR, endYearDisplayValue, dateAndAgeValues.endYear, setEndYearDisplayValue))
                                    } as RangeInputProp}
                                />
                            </div>
                            {(errorFieldToRecalculateTimeFrame && (errorFieldToRecalculateTimeFrame === InputTypes.STARTING_STRATEGY_YEAR || errorFieldToRecalculateTimeFrame === InputTypes.ENDING_STRATEGY_YEAR)) && memoizedErrorMessage}
                            <div className="layout-data-entry-form__field" data-testid="strategyTimeFrameAgeRange">
                                <label className={"h5"}>Client's Age Range</label>
                                <RangeInputs
                                    left={{
                                        value: startAgeDisplayValue,
                                        ariaLabel: "startAge",
                                        onChange: (e => setStartAgeDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                        readOnly: false,
                                        onBlur: (() => validateAndRecomputeValues(InputTypes.STARTING_AGE, startAgeDisplayValue, dateAndAgeValues.startAge, setStartAgeDisplayValue))
                                    } as RangeInputProp}
                                    right={{
                                        value: endAgeDisplayValue,
                                        ariaLabel: "endAge",
                                        onChange: (e => setEndAgeDisplayValue(isNaN(Number(e.target.value)) ? "" : Number(e.target.value))),
                                        readOnly: false,
                                        onBlur: (() => validateAndRecomputeValues(InputTypes.ENDING_AGE, endAgeDisplayValue, dateAndAgeValues.endAge, setEndAgeDisplayValue)
                                        )
                                    } as RangeInputProp}
                                />
                            </div>
                            {(errorFieldToRecalculateTimeFrame && (errorFieldToRecalculateTimeFrame === InputTypes.STARTING_AGE || errorFieldToRecalculateTimeFrame === InputTypes.ENDING_AGE)) && memoizedErrorMessage}
                            <div className="layout-data-entry-form__field"
                                 data-testid="strategyTimeFramePlanningPeriod">
                                <label className={"h5"}>Planning Period</label>
                                <span
                                    className={errorFieldToRecalculateTimeFrame && 'error-color'}
                                    role="planningPeriod"
                                    aria-label="planningPeriod">{`${planningPeriodValues.planningPeriod?.numberOfYears} years (${planningPeriodValues.firstName})`}</span>
                            </div>
                        </section>
                    }
                </article>

                <aside>
                    <div id="strategyWithTimeFrameStrategySummary">
                        <DataEntrySummary
                            title="Strategy Summary"
                            items={memoizedStrategySummary}
                        />
                    </div>
                    <div id="strategyWithTimeFrameEstimatedImpact" data-testid="estimatedImpactContainer">
                        <DataEntrySummary
                            title="Estimated Impact"
                            items={memoizedEstimatedImpact}
                        >
                            <div id="estimatedInfoTooltip">
                                <Icon name="status_info_outline" type="info" className='paddingright-sm'
                                      data-testid="estimatedImpactInfoIcon"/>
                                <div style={{fontStyle: "italic"}}>These values are estimates.</div>
                            </div>
                        </DataEntrySummary>
                    </div>
                </aside>
            </div>
            <DiscardModal
                itemType={"strategy"}
                isOpen={showCancelModal}
                onClickKeepEditing={() => setShowCancelModal(false)}
                onClickDiscardChanges={handleModalDiscardButton}
                isEditing={true}
            />
        </ScrollableContainer>
    )
}

export default StrategyWithTimeFrame;