import React, {ChangeEvent, CSSProperties, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    resetPortfolioReserveButtonStates,
    selectPortfolioReserveButtonStates,
    selectPortfolioReserveModel,
    selectPortfolioReserveUserPreferences,
    selectPortfolioReserveYears, selectPortfolioReserveOptions, setMonteCarlo, setPortfolioReserveButtonStates,
    setPortfolioReserveModel,
    setPortfolioReserveUserPreferences,
    setPortfolioReserveYears, setMarketStress,
} from "../PortfolioReserveSlice";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import {goalsApiClient} from "../../GoalsApiClient";
import {AlertBanner} from "../../../components";
import EditPortfolioReserveTableWarningModal from "../EditPortfolioReserveTableWarningModal"
import {IconState} from "../../../components/YearsInput/ClickableYearsInput";
import {PortfolioReserveResponse} from "../../models/PortfolioReserve";
import {LifestyleSpendingGoal, LifestyleSpendingGoalResponse} from "../../models/LifestyleSpendingGoal";
import SavePortfolioReserveModal from "../SavePortfolioReserveModal";
import {investableShortfall, portfolioReserveIsInvestablySufficient} from "../../../Portfolio/portfolioUtils";
import {useAppInsights} from "../../../AppInsights";
import {selectParticipatingInMeeting} from "../../../ClientManagement/Meeting/meetingSlice";
import {MeetingParticipation} from "../../../ClientManagement/Meeting/Meeting";
import {QuickSlide} from "../QuickSlide/QuickSlide";
import {CVAR_SLIDE_TEXT} from "../QuickSlide/constants";
import useProfileEditableState from "../../../hooks/useProfileEditableState";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {HowManyYearsRow} from "./HowManyYearsRow";
import {HowRiskyRow} from "./HowRiskyRow";
import {ExcessAssetsRow} from "./ExcessAssetsRow";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";
import {ProfileResponse} from "../../../ClientManagement/models/ProfileResponse";
import {AssetAllocationRow} from "./AssetAllocationRow";

enum QuickSlideState {
    NONE,
    MONTE_CARLO,
    CVAR
}

const EditPortfolioReserveTable = () => {
    const dispatch = useAppDispatch();
    const portfolioReserveModel = useAppSelector(selectPortfolioReserveModel);
    const {
        assets,
        investorGroup,
        profile,
        portfolioReserve,
        lifestyleSpendingGoal,
        nonLifestyleGoals,
        familyGoals,
        taxLiabilities
    } = portfolioReserveModel;


    const {isArchiveReadOnly} = useProfileEditableState();
    const [disableSetPortfolioReserveButton, setDisableSetPortfolioReserveButton] = useState(false);
    const [stateOfFBNIToggle, setStateOfFBNIToggle] = useState(lifestyleSpendingGoal.userInputs.isFundedByNonInvestableAssets)
    const [toggledFBNI, setToggledFBNI] = useState(false)
    const [editPortfolioReserve, setEditPortfolioReserve] = useState(portfolioReserve)
    const [editPortfolioReserveYears, setEditPortfolioReserveYears] = useState({
        prTargetInputYears: portfolioReserve.portfolioReserveTargetLength,
        minimumRiskInputYears: portfolioReserve.minimumRiskReserveLength,
    });
    const {prTargetInputYears, minimumRiskInputYears} = useAppSelector(selectPortfolioReserveYears);

    const {isActivePortfolioReserve: clientProfileHasActivePortfolioReserve} = useAppSelector(selectProfile);

    const prTargetAssetAllocationInfo = {
        totalRiskAssetsPercent: editPortfolioReserve.targetLengthAllocation.riskAssetPercent,
        totalRiskControlAssetsPercent: editPortfolioReserve.targetLengthAllocation.riskControlPercent
    }
    const minimumRiskAssetAllocation = {
        totalRiskAssetsPercent: editPortfolioReserve.minimumRiskAllocation.riskAssetPercent,
        totalRiskControlAssetsPercent: editPortfolioReserve.minimumRiskAllocation.riskControlPercent
    }
    const riskControlAllocationDifference = Math.round(minimumRiskAssetAllocation.totalRiskControlAssetsPercent) - Math.round(prTargetAssetAllocationInfo.totalRiskControlAssetsPercent)

    let riskControlDiffDesc = "";
    if (riskControlAllocationDifference > 0) {
        riskControlDiffDesc = `A longer Reserve Target means more assets moved to Risk Control (${riskControlAllocationDifference}%).`;
    }
    if (riskControlAllocationDifference < 0) {
        riskControlDiffDesc = `A shorter Reserve Target means fewer assets moved to Risk Control (${riskControlAllocationDifference}%).`;
    }
    const portfolioReserveUserPreferences = useAppSelector(selectPortfolioReserveUserPreferences)

    const {
        editablePortfolioReserve,
        clickedPortfolioReserveTargetLengthInput,
        clickedLifestyleMinimumRiskInput,
        navigatedBeforeSavingPortfolioReserve,
    } = portfolioReserveUserPreferences
    const portfolioReserveOptions = useAppSelector(selectPortfolioReserveOptions)
    const portfolioReserveButtonState = useAppSelector(selectPortfolioReserveButtonStates);
    const minRiskDescriptionDefault = editPortfolioReserve.portfolioReserveTargetLength > editPortfolioReserve.minimumRiskReserveLength ?
        "A shorter Reserve Target means a wider range of expected outcomes." :
        "A longer Reserve Target means a narrower range of expected outcomes.";
    let [riskDescriber, setRiskDescriber] = useState<string>("less risk");
    let [minRiskDescriptionText, setMinRiskDescriptionText] = useState<string>(minRiskDescriptionDefault);

    const initialPortfolioReserveTargetLength = useRef(portfolioReserve.initialPortfolioReserveTargetLength);
    const initialMinimumRiskReserveLength = useRef(portfolioReserve.minimumRiskReserveLength);
    const [didMount, setDidMount] = useState<boolean>(false);
    const yearsInputWidth: CSSProperties = {
        width: "302px"
    }

    const prTargetInputMatchesProfilePR = (prTargetInputYears === profile.portfolioReserveTargetLength)
    const prTargetInputMatchesPortfolioSupports = (prTargetInputYears === initialPortfolioReserveTargetLength.current)
    const changedFBNIValue = !(stateOfFBNIToggle === lifestyleSpendingGoal.userInputs.isFundedByNonInvestableAssets)
    const changedPrValue = ((profile.portfolioReserveTargetLength !== undefined) ? (!prTargetInputMatchesProfilePR) : (!prTargetInputMatchesPortfolioSupports))
    const [savedPortfolioReserveClicked, setSavedPortfolioReserveClicked] = useState<boolean>(false);

    const reserveTargetInputController = useRef(new AbortController())
    const displayInsufficientWarning = !portfolioReserveIsInvestablySufficient({
        nonLifestyleGoals,
        familyGoals,
        totalInvestableValue: assets.totalInvestableValue,
        lifestylePresentValue: editPortfolioReserve.lifestylePresentValues.reserveTarget,
        taxLiabilities
    });
    const displayInsufficientWarningForMinimumRiskYears = !portfolioReserveIsInvestablySufficient({
        nonLifestyleGoals,
        familyGoals,
        totalInvestableValue: assets.totalInvestableValue,
        lifestylePresentValue: editPortfolioReserve.lifestylePresentValues.minimumRisk,
        taxLiabilities
    });
    const investableInsufficientAmount = investableShortfall({
        nonLifestyleGoals,
        familyGoals,
        totalInvestableValue: assets.totalInvestableValue,
        lifestylePresentValue: editPortfolioReserve.lifestylePresentValues.reserveTarget,
        taxLiabilities
    });
    const investableInsufficientAmountForMinimumRiskYears = investableShortfall({
        nonLifestyleGoals,
        familyGoals,
        totalInvestableValue: assets.totalInvestableValue,
        lifestylePresentValue: editPortfolioReserve.lifestylePresentValues.minimumRisk,
        taxLiabilities
    });

    const appInsights = useAppInsights();
    const participatingInMeeting = useAppSelector(selectParticipatingInMeeting)!;
    const [quickSlideState, setQuickSlideState] = useState<QuickSlideState>(QuickSlideState.NONE)

    useEffect(() => {
        // Anything in here is fired on component mount.
        const startTime: number = new Date().valueOf();
        return () => {
            // Anything in here is fired on component unmount.
            const endTime: number = new Date().valueOf();
            appInsights.trackEvent({
                name: 'EditPortfolioReserve',
                properties:
                    {
                        screen: 'Edit Portfolio Reserve',
                        action: 'Time Spent',
                        meetingStatus: participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING,
                        data: {
                            timeSpent: endTime - startTime // Gives the time Spent in milliseconds
                        }
                    }
            })
        }
    }, [])

    useEffect(() => {
        dispatch(setPortfolioReserveUserPreferences({
            ...portfolioReserveUserPreferences,
            clickedPortfolioReserveTargetLengthInput: false,
            clickedLifestyleMinimumRiskInput: false,
            isMonteCarloLoading: true
        }));
        if (profile.portfolioReserveTargetLength != undefined) {
            goalsApiClient.getMonteCarlo(profile.id, {
                selectedReserveTarget: String(profile.portfolioReserveTargetLength),
            }).then(response => {
                dispatch(setMonteCarlo(response))
            })
        } else {
            goalsApiClient.getMonteCarlo(profile.id).then(response => {
                dispatch(setMonteCarlo(response))
            })
        }
        dispatch(setPortfolioReserveUserPreferences({
            ...portfolioReserveUserPreferences,
            isMonteCarloLoading: false,
            editablePortfolioReserve: false
        }));
        dispatch(setPortfolioReserveButtonStates({
            ...portfolioReserveButtonState,
            portfolioRisk: false,
            excessAssets: false,
            assetAllocation: false,
        }));
        if(releaseToggle!.enableMarketStress){
            goalsApiClient.getMarketStress(profile.id).then(response => {
                dispatch(setMarketStress(response))
            });
        }
    }, []);

    function updateDescriptionText(prYears: number, minimumRiskYears: number) {
        if (prYears < minimumRiskYears) {
            setRiskDescriber("less risk");
            setMinRiskDescriptionText("A longer Reserve Target means a narrower range of expected outcomes.");
        } else if (prYears > minimumRiskYears) {
            setRiskDescriber("more risk");
            setMinRiskDescriptionText("A shorter Reserve Target means a wider range of expected outcomes.");
        } else {
            setRiskDescriber(riskDescriber)
            setMinRiskDescriptionText(minRiskDescriptionText)
        }
    }

    useEffect(() => {
        if (!isValidNumber(prTargetInputYears) || !isValidNumber(minimumRiskInputYears)) {
            return;
        }
        updateDescriptionText(prTargetInputYears, minimumRiskInputYears);

        const shouldFetch = didMount
            && (portfolioReserveButtonState.excessAssets || prTargetInputYears >= 0)
            && isValidNumber(prTargetInputYears)
            && isValidNumber(minimumRiskInputYears);

        if (shouldFetch) {
            fetchPortfolioReserveData(prTargetInputYears, minimumRiskInputYears);
        } else {
            setDidMount(true);
        }
    }, [prTargetInputYears, minimumRiskInputYears, stateOfFBNIToggle]);

    const handlePRTargetChangeValue = (value: number) => {
        setEditPortfolioReserveYears({
            ...editPortfolioReserveYears,
            prTargetInputYears: value
        })

        dispatch(setPortfolioReserveYears({...editPortfolioReserveYears, prTargetInputYears: value}));
    }

    const handleIncreasePRYear = async () => {
        if (isValidNumber(prTargetInputYears) && prTargetInputYears + 1 <= investorGroup.planningPeriod.numberOfYears) {
            handlePRTargetChangeValue(prTargetInputYears + 1)
        }
    }

    const handleDecreasePRYear = async () => {
        if (isValidNumber(prTargetInputYears) && prTargetInputYears - 1 >= 0) {
            handlePRTargetChangeValue(prTargetInputYears - 1)
        }
    }

    const handleReserveTargetBlur = async (selectedReserveTarget: number) => {
        if (isValidNumber(prTargetInputYears) && prTargetInputYears >= 0) {
            if (selectedReserveTarget > investorGroup.planningPeriod.numberOfYears) {
                setEditPortfolioReserveYears({
                    ...editPortfolioReserveYears,
                    prTargetInputYears: investorGroup.planningPeriod.numberOfYears
                })

                dispatch(setPortfolioReserveYears({
                    ...editPortfolioReserveYears,
                    prTargetInputYears: investorGroup.planningPeriod.numberOfYears
                }));
            }
            dispatch(setPortfolioReserveUserPreferences({
                ...portfolioReserveUserPreferences,
                clickedPortfolioReserveTargetLengthInput: true,
            }));
        }
    }
    const updatePortfolioReserveData = async (selectedReserveTarget: number, selectedMinimumRisk: number, abortSignal: AbortSignal) => {
        setEditPortfolioReserveYears(
            {
                prTargetInputYears: selectedReserveTarget,
                minimumRiskInputYears: selectedMinimumRisk
            }
        )
        setDisableSetPortfolioReserveButton(true);
        goalsApiClient.getPortfolioReserve(profile.id,
            {
                selectedReserveTarget: String(selectedReserveTarget),
                selectedMinimumRisk: String(selectedMinimumRisk),
                selectedFBNI: String(stateOfFBNIToggle)
            },
            abortSignal
        ).then(response => {
            setEditPortfolioReserve(response);
            setDisableSetPortfolioReserveButton(false);
        });
    }

    const hideMinRiskHeaderText = () => {
        dispatch(setPortfolioReserveUserPreferences({
            editablePortfolioReserve: true,
            clickedPortfolioReserveTargetLengthInput: false,
            clickedLifestyleMinimumRiskInput: true,
            editedMinimumRisk: true
        }))
    }

    const handleMinRiskChangeValue = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
        setEditPortfolioReserveYears({
            ...editPortfolioReserveYears,
            minimumRiskInputYears: value
        })
        hideMinRiskHeaderText()

        dispatch(setPortfolioReserveYears({...editPortfolioReserveYears, minimumRiskInputYears: value}));
    }

    const handleIncreaseMinYear = async () => {
        if (isValidNumber(minimumRiskInputYears) && minimumRiskInputYears + 1 < investorGroup.planningPeriod.numberOfYears) {
            setEditPortfolioReserveYears({
                ...editPortfolioReserveYears,
                minimumRiskInputYears: minimumRiskInputYears + 1
            })
            hideMinRiskHeaderText()

            dispatch(setPortfolioReserveYears({
                ...editPortfolioReserveYears,
                minimumRiskInputYears: minimumRiskInputYears + 1
            }));
        }
    }

    const handleDecreaseMinYear = async () => {
        if (isValidNumber(minimumRiskInputYears) && minimumRiskInputYears - 1 >= 0) {
            setEditPortfolioReserveYears({
                ...editPortfolioReserveYears,
                minimumRiskInputYears: minimumRiskInputYears - 1
            })
            hideMinRiskHeaderText()

            dispatch(setPortfolioReserveYears({
                ...editPortfolioReserveYears,
                minimumRiskInputYears: minimumRiskInputYears - 1
            }));
        }
    }

    const handleMinimumRiskBlur = async (selectedMinimumRisk: number) => {
        if (isValidNumber(minimumRiskInputYears) && minimumRiskInputYears >= 0) {
            if (selectedMinimumRisk > investorGroup.planningPeriod.numberOfYears) {
                setEditPortfolioReserveYears({
                    ...editPortfolioReserveYears,
                    minimumRiskInputYears: investorGroup.planningPeriod.numberOfYears
                })

                dispatch(setPortfolioReserveYears({
                    ...editPortfolioReserveYears,
                    minimumRiskInputYears: investorGroup.planningPeriod.numberOfYears
                }));
            }
        }
    }

    const fetchPortfolioReserveData = (targetInputYears: number, minimumRiskYears: number) => {
        const newReserveTargetController = new AbortController()
        const {signal} = newReserveTargetController;

        reserveTargetInputController.current.abort()
        reserveTargetInputController.current = newReserveTargetController
        updatePortfolioReserveData(targetInputYears, minimumRiskYears, signal).then();
        dispatch(setPortfolioReserveUserPreferences({
            ...portfolioReserveUserPreferences,
            isMonteCarloLoading: true
        }))
        goalsApiClient.getMonteCarlo(profile.id,
            {
                selectedReserveTarget: String(targetInputYears),
                selectedMinimumRisk: String(minimumRiskYears),
                selectedFBNI: String(stateOfFBNIToggle)
            }, signal
            ).then(response => {
            dispatch(setMonteCarlo(response))
            dispatch(setPortfolioReserveUserPreferences({
                ...portfolioReserveUserPreferences,
                isMonteCarloLoading: false
            }))
        })
    }

    const updatePortfolioReserveResponse = (): PortfolioReserveResponse => {
        return {
            ...portfolioReserve,
            portfolioReserveTargetLength: editPortfolioReserve.portfolioReserveTargetLength,
            cvar: {
                cvarPortfolioSupports: editPortfolioReserve.cvar.cvarPortfolioSupports,
                cvarMinimumRisk: portfolioReserve.cvar.cvarMinimumRisk
            },
            lifestylePresentValues: {
                reserveTarget: editPortfolioReserve.lifestylePresentValues.reserveTarget,
                minimumRisk: portfolioReserve.lifestylePresentValues.minimumRisk
            },
            targetLengthAllocation: editPortfolioReserve.targetLengthAllocation,
            adjustedTargetLengthAllocation: editPortfolioReserve.adjustedTargetLengthAllocation
        }
    }

    const updateLifestyleSpendingGoal = (): LifestyleSpendingGoal => {
        return {
            ...lifestyleSpendingGoal,
            userInputs: {
                ...lifestyleSpendingGoal.userInputs,
                isFundedByNonInvestableAssets: stateOfFBNIToggle
            },
            calculatedFields: {
                ...editPortfolioReserve.lifestylePresentValues.reserveTarget
            }
        }
    }
    const displayTargetLengthLabel = () => {
        if (prTargetInputMatchesProfilePR) {
            return <>What is my <span style={{fontWeight: "410"}}>Reserve Target</span>?</>;
        } else if (prTargetInputMatchesPortfolioSupports) {
            return <>What does my <span style={{fontWeight: "410"}}>current portfolio support today</span>?</>;
        } else {
            return <>What if I <span style={{fontWeight: "410"}}>explore a different Reserve Target</span>?</>;
        }
    }

    const handleClickSetPortfolioReserve = async () => {
        dispatch(resetPortfolioReserveButtonStates());

        const promises: Promise<any>[] = [];

        const isFBNIOnAndInsufficientInTargetLength = toggledFBNI
            && editPortfolioReserve.lifestylePresentValues.reserveTarget.presentValueForInsufficientYears > 0;
        const isFBNIToggledOffFromOn = toggledFBNI && !stateOfFBNIToggle
        if (isFBNIOnAndInsufficientInTargetLength || isFBNIToggledOffFromOn) {
            const goalsPromise: Promise<LifestyleSpendingGoalResponse> = goalsApiClient.postLifeStyleGoal(profile.id, {
                id: lifestyleSpendingGoal.id,
                userInputs: {...lifestyleSpendingGoal.userInputs, isFundedByNonInvestableAssets: stateOfFBNIToggle},
                lengthOfPlanningPeriod: investorGroup.planningPeriod.numberOfYears
            },)
            promises.push(goalsPromise)
        }

        const clientManagementPromise: Promise<ProfileResponse> = clientManagementApiClient.putProfile({
            ...profile,
            portfolioReserveTargetLength: prTargetInputYears
        })
        promises.push(clientManagementPromise)


        Promise.all(promises).then((responseArray) => {
            let updatedProfile;
            if (responseArray.length == 1) {
                updatedProfile = responseArray[0];
                console.info("PR length is saved for the profile")
            } else {
                updatedProfile = responseArray[1]
                console.info("New FBNI toggle value and PR length is saved for the profile")
            }
            dispatch(setPortfolioReserveModel({
                ...portfolioReserveModel,
                profile: updatedProfile,
                portfolioReserve: updatePortfolioReserveResponse(),
                lifestyleSpendingGoal: updateLifestyleSpendingGoal()
            }))
            dispatch(setPortfolioReserveUserPreferences({
                ...portfolioReserveUserPreferences,
                navigatedBeforeSavingPortfolioReserve: false
            }))
        }).then(() => {
            setSavedPortfolioReserveClicked(true)
            appInsights.trackEvent({
                name: 'EditPortfolioReserve',
                properties:
                    {
                        screen: 'Edit Portfolio Reserve',
                        action: 'Set Portfolio Reserve Button Click',
                        meetingStatus: participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING,
                        data: {
                            hasRecommendedPortfolioReserve: prTargetInputMatchesPortfolioSupports,
                            portfolioReserveLength: prTargetInputYears
                        }
                    }
            })
        }).catch(error => {
            console.error('Could not save portfolio reserve target length', error.message)
        });
    }

    const handleClickPortfolioReserveTargetLength = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        dispatch(setPortfolioReserveUserPreferences({
            ...portfolioReserveUserPreferences,
            editablePortfolioReserve: true,
            clickedPortfolioReserveTargetLengthInput: true,
            clickedLifestyleMinimumRiskInput: false,
        }))
    }

    const handleClickLifestyleMinimumRiskLength = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        dispatch(setPortfolioReserveUserPreferences({
            ...portfolioReserveUserPreferences,
            editablePortfolioReserve: true,
            clickedPortfolioReserveTargetLengthInput: false,
            clickedLifestyleMinimumRiskInput: true
        }))
    }

    const isReserveTargetSet = ((profile.portfolioReserveTargetLength !== undefined && profile.portfolioReserveTargetLength !== null) && profile.portfolioReserveTargetLength >= 0)
    const portfolioRiskRowActive = portfolioReserveButtonState.portfolioRisk;
    const excessAssetsRowActive = portfolioReserveButtonState.excessAssets;
    const assetAllocationRowActive = portfolioReserveButtonState.assetAllocation;
    const releaseToggle = useAppSelector(selectReleaseToggles);

    function getPortfolioReserveTargetLengthInputIconState() {
        if (editablePortfolioReserve && !clickedPortfolioReserveTargetLengthInput) {
            return IconState.PENCIL
        } else if (editablePortfolioReserve && clickedPortfolioReserveTargetLengthInput) {
            return IconState.ARROWS
        }
        return IconState.NONE
    }

    function getlifestyleMinimumRiskInputIconState() {
        if (editablePortfolioReserve && !clickedLifestyleMinimumRiskInput) {
            return IconState.PENCIL
        } else if (editablePortfolioReserve && clickedLifestyleMinimumRiskInput) {
            return IconState.ARROWS
        }
        return IconState.NONE
    }

    function handleFBNIToggle() {
        dispatch(setPortfolioReserveUserPreferences({
            ...portfolioReserveUserPreferences,
            clickedPortfolioReserveTargetLengthInput: false,
            clickedLifestyleMinimumRiskInput: false
        }))
        setStateOfFBNIToggle(!stateOfFBNIToggle)
        setToggledFBNI(true)
    }

    return (
        <div className="edit-portfolio-reserve-page__table" role="table">
            <EditPortfolioReserveTableWarningModal editedReserveLength={changedPrValue} editedFBNI={changedFBNIValue}/>
            <SavePortfolioReserveModal setPRClicked={savedPortfolioReserveClicked} displayInsufficientWarning ={displayInsufficientWarning}/>
            {(quickSlideState === QuickSlideState.MONTE_CARLO) &&
                <QuickSlide
                    title={"Monte Carlo Range of Outcomes*"}
                    imageSrc={"/MonteCarloBellCurve-" + portfolioReserveOptions.monteCarloSelectedPercentile.toString() + ".svg"}
                    children={<>
                        <div style={{paddingTop: '13px', color:'#6B6E6F', fontStyle: 'italic'}}>
                            *The result of the Monte Carlo is a non-normal distribution of outcomes. This visual represents a normal distribution for illustrative purposes.
                        </div>
                </>}
                    closeHandler={() => setQuickSlideState(QuickSlideState.NONE)}
                />
            }
            {(quickSlideState === QuickSlideState.CVAR) &&
                <QuickSlide
                    title={"Conditional Value at Risk"}
                    subtext={" (Average of worst 5% of expected returns)"}
                    imageSrc={"/CVaRGraph.svg"}
                    children={<>
                        <div className="marginbottom-lg">{CVAR_SLIDE_TEXT}</div>
                    </>}
                    closeHandler={() => setQuickSlideState(QuickSlideState.NONE)}
                />
            }
            <AlertBanner
                showAlert={clientProfileHasActivePortfolioReserve}
                icon="info"
                type="warning"
                fullWidth={false}
                className="margintop-20a"
            >
                The active Portfolio Reserve does not impact CVaR or proposed asset allocation values on this page.
                Lifestyle goal calculations do not reflect the drawdown to risk control.
            </AlertBanner>
            <div
                className='edit-portfolio-reserve-page__table__row'
                role="row"
            >
                <HowManyYearsRow prTargetInputYears={prTargetInputYears} profile={profile}
                                 element={displayTargetLengthLabel()}
                                 onClickPortfolioReserveTargetLength={handleClickPortfolioReserveTargetLength}
                                 onClickLifestyleMinimumRiskInput={handleClickLifestyleMinimumRiskLength}
                                 onIncrementTargetLength={handleIncreasePRYear}
                                 onDecrementTargetLength={handleDecreasePRYear}
                                 onBlurTargetLength={(e: ChangeEvent<HTMLInputElement>) => handleReserveTargetBlur(Number(e.target.value))}
                                 portfolioReserveTargetLengthInputIconState={getPortfolioReserveTargetLengthInputIconState()}
                                 lifestyleMinimumRiskInputIconState={getlifestyleMinimumRiskInputIconState()}
                                 editablePortfolioReserve={editablePortfolioReserve}
                                 onChangeTargetLength={handlePRTargetChangeValue}
                                 navigatedBeforeSavingPortfolioReserve={navigatedBeforeSavingPortfolioReserve}
                                 clickedInputYear={clickedPortfolioReserveTargetLengthInput}
                                 clickedLifestyleMinimumRiskInput={clickedLifestyleMinimumRiskInput}
                                 displayInsufficientWarning={displayInsufficientWarning}
                                 displayInsufficientWarningForMinimumRiskYears={displayInsufficientWarningForMinimumRiskYears}
                                 yearsInputWidth={yearsInputWidth}
                                 initialPortfolioReserveTargetLength={initialPortfolioReserveTargetLength}
                                 clientProfileHasActivePortfolioReserve={clientProfileHasActivePortfolioReserve}
                                 archiveReadOnly={isArchiveReadOnly}
                                 onClickSetPortfolioReserve={handleClickSetPortfolioReserve}
                                 portfolioReserveOptions={portfolioReserveOptions}
                                 editPortfolioReserve={editPortfolioReserve}
                                 riskDescriber={riskDescriber} onIncrementMinimumRisk={handleIncreaseMinYear}
                                 onDecrementMinimumRisk={handleDecreaseMinYear}
                                 onBlurMinimumRisk={(e: ChangeEvent<HTMLInputElement>) => handleMinimumRiskBlur(Number(e.target.value))}
                                 minimumRisk={minimumRiskInputYears}
                                 onChangeMinimumRisk={handleMinRiskChangeValue}
                                 initialMinimumRiskReserveLength={initialMinimumRiskReserveLength}
                                 investableInsufficentAmount={investableInsufficientAmount}
                                 investableInsufficientAmountForMinimumRiskYears={investableInsufficientAmountForMinimumRiskYears}
                                 planningPeriod={investorGroup.planningPeriod}
                                 disableSetPortfolioReserveButton={disableSetPortfolioReserveButton}
                                 isValidReserveTargetLength={isReserveTargetSet}
                                 isOn={stateOfFBNIToggle}
                                 onToggle={() => handleFBNIToggle()}
                                 releaseToggle={releaseToggle}
                                 toggledFBNI={toggledFBNI}
                />

            </div>
            <div
                className={`edit-portfolio-reserve-page__table__row`}
                role="row">
                <HowRiskyRow portfolioRiskRowActive={portfolioRiskRowActive}
                             onClickHowRiskInfoTip={() => setQuickSlideState(QuickSlideState.CVAR)}
                             editPortfolioReserve={editPortfolioReserve} assets={assets}/>

            </div>
            <div
                className={`edit-portfolio-reserve-page__table__row`}
                role="row">
                <ExcessAssetsRow excessAssetsRowActive={excessAssetsRowActive}
                                 portfolioReserveOptions={portfolioReserveOptions}
                                 onClickExcessAssetsInfoTip={() => setQuickSlideState(QuickSlideState.MONTE_CARLO)}
                                 editPortfolioReserveData={editPortfolioReserve}
                                 minRiskDescriptionText={minRiskDescriptionText}/>
            </div>
            <AssetAllocationRow
                assetAllocationRowActive={assetAllocationRowActive}
                reserveTargetAssetAllocation={prTargetAssetAllocationInfo}
                minimumRiskAssetAllocation={minimumRiskAssetAllocation}
                riskControlDiffDesc={riskControlDiffDesc}/>
        </div>
    );

}

const isValidNumber = (value: number | undefined): value is number => {
    return value !== undefined && !isNaN(value);
};

export default EditPortfolioReserveTable;
