export const enum FailedAPICall {
    ESTIMATED_IMPACT = "Estimated Impact",
    REFERENCE_DATA = "Reference Data",
}

export const enum StrategyType {
    STATE_OF_RESIDENCY = "STATE_OF_RESIDENCY",
    ANNUAL_GIFTING = "ANNUAL_GIFTING",
    GIFT_TO_GRANTOR_TRUST = "GTGT"
}

export type RateDto = {
    id: string,
    description: string,
    value: number,
}

export type GrowthRates = {
    rateDtos: RateDto[],
};

export type StrategyTimeFrame = {
    strategyId: string | undefined,
    strategyType: StrategyType,
    sequenceNumber?: number,
    contributionAmount: number,
    yearsOfFlow: number,
    yearsUntilFlow: number,
    yearsOfGrantorStatus: number
};

export type EstimatedImpact = {
    amountToBeneficiaries: number | undefined,
    impactToEstEstateTax: number | undefined
};

export type FutureEstateValue = {
    totalAmountToBeneficiaries: number,
    totalEstEstateTax: number
}

export const initialFutureEstateValue: FutureEstateValue = {
    totalAmountToBeneficiaries: 0,
    totalEstEstateTax: 0
}

export const initialEstimatedImpact: EstimatedImpact = {
    amountToBeneficiaries: undefined,
    impactToEstEstateTax: undefined
};

// State of Residency Strategy Types
export type StateInputDto = {
    id: string,
    name: string,
    forecastedEstateTaxRate: number
};

export type StateEstateTransferRates = {
    stateInputsDTOs: StateInputDto[],
};

export type StateOfResidencyStrategy = {
    id: string | undefined,
    description: string,
    selectedStateOfResidency: string,
    estimatedImpact: EstimatedImpact;
}

export const initialStateOfResidencyStrategy: StateOfResidencyStrategy = {
    id: undefined,
    description: "State of Residency",
    selectedStateOfResidency: "",
    estimatedImpact: initialEstimatedImpact
}

// Annual Gifting Strategy Types
export type AnnualGiftingStrategy = StrategyTimeFrame & {
    description: string,
    estimatedImpact: EstimatedImpact
}

export const initialAnnualGiftingStrategy: AnnualGiftingStrategy = {
    strategyId: undefined,
    strategyType: StrategyType.ANNUAL_GIFTING,
    description: "Annual Gifting",
    contributionAmount: 0,
    yearsUntilFlow: 0,
    yearsOfFlow: 1,
    yearsOfGrantorStatus: 0,
    estimatedImpact: initialEstimatedImpact
}

// Gift to Grantor Trust Strategy Types
export type GiftToGrantorTrustStrategy = StrategyTimeFrame & {
    description: string,
    estimatedImpact: EstimatedImpact
}

export const initialGiftToGrantorTrustStrategy: GiftToGrantorTrustStrategy =  {
    strategyId: undefined,
    strategyType: StrategyType.GIFT_TO_GRANTOR_TRUST,
    description: "Gift to Grantor Trust",
    contributionAmount: 0,
    yearsUntilFlow: 0,
    yearsOfFlow: 1,
    yearsOfGrantorStatus: 0,
    estimatedImpact: initialEstimatedImpact
}

// WealthPO Summary Types
export type StrategyDetailsSectionInfo = {
    strategyId: string,
    description: string,
    strategyType: StrategyType,
    amountToBeneficiaries: number,
    impactToEstEstateTax: number,
}

export type EstimatedImpactSummary = {
    onlyStateOfResidencyStrategySelected: boolean;
    totalImpactToAmountToBeneficiaries: number | undefined,
    totalImpactToEstEstateTax: number | undefined,
}
export const initialEstimatedImpactSummary: EstimatedImpactSummary = {
    onlyStateOfResidencyStrategySelected: false,
    totalImpactToAmountToBeneficiaries: undefined,
    totalImpactToEstEstateTax: undefined,
}

export type WealthPOSummaryResponse = {
    stateOfResidencyStrategy: StateOfResidencyStrategy;
    annualGiftingStrategies: AnnualGiftingStrategy[];
    giftToGrantorTrustStrategies: GiftToGrantorTrustStrategy[];
    estimatedImpactSummary: EstimatedImpactSummary;
    futureEstateValueWithStrategies: FutureEstateValue;
    futureEstateValueWithoutStrategies: FutureEstateValue;
}
export const initialWealthPOSummaryResponse: WealthPOSummaryResponse = {
    stateOfResidencyStrategy: initialStateOfResidencyStrategy,
    annualGiftingStrategies: [],
    giftToGrantorTrustStrategies: [],
    estimatedImpactSummary: initialEstimatedImpactSummary,
    futureEstateValueWithStrategies: initialFutureEstateValue,
    futureEstateValueWithoutStrategies: initialFutureEstateValue
};