import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../routes/types";
import StrategyWithTimeFrame from "./components/StrategyWithTimeFrame";
import {
    EstimatedImpact,
    GiftToGrantorTrustStrategy,
    initialEstimatedImpact,
    initialGiftToGrantorTrustStrategy,
    StrategyTimeFrame
} from "./WealthPOTypes";
import {CreateOrEditStrategyParameters} from "./WealthPOUtils";
import {wealthPOApiClient} from "./WealthPOApiClient";
import {useAppSelector} from "../store/hooks";
import {selectGiftToGrantorTrustStrategies} from "./WealthPlanOptimizerSlice";

interface AddEditGiftToGrantorProps {
    refreshStrategiesSummary: () => void;
}

const AddEditGiftToGrantorTrust = ({refreshStrategiesSummary}: AddEditGiftToGrantorProps) => {
    const {id, strategyId} = useParams<RouteWithIdAndStrategyId>();
    const history = useHistory();


    const giftToGrantorTrustStrategies = useAppSelector(selectGiftToGrantorTrustStrategies);
    const giftToGrantorTrustStrategy = strategyId !== undefined
        ? giftToGrantorTrustStrategies.find((strategy => strategy.strategyId === strategyId))
        : initialGiftToGrantorTrustStrategy;

    const getEstimatedImpact = (requestBody: StrategyTimeFrame, signal?: AbortSignal): Promise<EstimatedImpact> => {
        return wealthPOApiClient.getEstimatedImpactForAnnualGifting(id, requestBody, signal);
    }

    const handleSaveClick = (requestParameters: CreateOrEditStrategyParameters) => {
        const {description, contributionAmount, yearsUntilFlow, yearsOfGrantorStatus} = requestParameters;

        let requestBody = {
            description,
            contributionAmount,
            yearsUntilFlow,
            yearsOfGrantorStatus
        } as GiftToGrantorTrustStrategy

        if (strategyId) {
            requestBody = {
                ...requestBody,
                strategyId: strategyId,
            };

            wealthPOApiClient.editGiftToGrantorTrustStrategy(id, requestBody)
                .then(() => {
                    refreshStrategiesSummary();
                    history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/Summary`)
                });
        } else {
            wealthPOApiClient.createGiftToGrantorTrustStrategy(id, requestBody)
                .then(() => {
                    refreshStrategiesSummary();
                    history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/Summary`)
                });
        }
    }

    return (
        <StrategyWithTimeFrame profileId={id}
                               header={`${strategyId === undefined ? "Add" : "Edit"} Gift to Grantor Trust`}
                               strategyDetailsDescription={strategyId === undefined ? "Gift to Grantor Trust" : giftToGrantorTrustStrategy!.description}
                               strategyDetailsContributionAmount={strategyId === undefined ? 0 : giftToGrantorTrustStrategy!.contributionAmount}
                               timeFrameYearsUntilFlow={strategyId === undefined ? 0 : giftToGrantorTrustStrategy!.yearsUntilFlow}
                               timeFrameYearsOfFlow={strategyId === undefined ? 0 : giftToGrantorTrustStrategy!.yearsOfFlow}
                               timeFrameYearsOfGrantorStatus={strategyId === undefined ? 0 : giftToGrantorTrustStrategy!.yearsOfGrantorStatus}
                               getEstimatedImpact={getEstimatedImpact}
                               initialStateEstimatedImpact={initialEstimatedImpact}
                               handleSaveClick={handleSaveClick}
                               isAnnualGift={false}
        />)
}

export default AddEditGiftToGrantorTrust